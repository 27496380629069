import { CSSProperties, FC, MouseEventHandler, useLayoutEffect, useRef } from 'react';
import styles from './anchor.module.css';

export type AnchorProps = {
  anchor: HTMLElement | null;
  anchorOrigin?: { horizontal: 'left' | 'right' };
  onClose: VoidFunction;
};

export const Anchor: FC<AnchorProps> = ({
  anchor,
  anchorOrigin = { horizontal: 'left' },
  onClose,
  children,
}) => {
  const backdropRef = useRef<HTMLDivElement>(null);

  const handleBackdropClick: MouseEventHandler = event => {
    if (event.target === backdropRef.current) onClose();
  };

  const anchorRect = anchor!.getBoundingClientRect();
  const menuStyle: CSSProperties = {
    position: 'absolute',
    top: anchorRect.top + anchorRect.height,
    ...(anchorOrigin.horizontal === 'left'
      ? { left: anchorRect.left }
      : { right: window.innerWidth - anchorRect.right }),
  };

  useLayoutEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, []);

  return (
    <div ref={backdropRef} onClick={handleBackdropClick} className={styles.backdrop}>
      <div style={menuStyle}>{children}</div>
    </div>
  );
};
