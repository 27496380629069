import { MenuDirection } from '../menu';

export type DropdownWithDynamicTitle = {
  discriminatorColumn: DropdownDiscriminatorType.DYNAMIC;
  disabledTooltip?: string;
  singleSelected?: string;
  multipleSelected: string;
  noneSelectedError: string;
  informationTooltip?: string;
};

export type DropdownWithStaticTitle = {
  discriminatorColumn: DropdownDiscriminatorType.STATIC;
  disabledTooltip: string;
  title: string;
  noneSelectedError: string;
  informationTooltip?: string;
};

export enum DropdownDiscriminatorType {
  DYNAMIC,
  STATIC,
}

export type DropdownLabel = DropdownWithDynamicTitle | DropdownWithStaticTitle;

export type Props = {
  options: DropdownOption[];
  selectedByDefaultOptions: DropdownOption[];
  showSearchBox?: boolean;
  isDisabled?: boolean;
  onBoardingTooltipConfig?: { storageKey: string; text: string };
  texts: DropdownLabel;
  onApply: (options: DropdownOption[]) => void;
  menuDirection?: MenuDirection;
  tracking?: { prefix: string };
  className?: string;
};

export type DropdownOption = { key: string; value?: number | string };
