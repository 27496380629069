import React, { FC, useState } from 'react';
import { showNotification } from '../../../services/notification-service';
import { NotificationLevel } from '../../../constants';

interface NotificationData {
  title: string;
  message: string;
  link?: string;
}

export interface NotificationSenderProps {
  onSend: (data: NotificationData) => Promise<void>;
}

export const NotificationSender: FC<NotificationSenderProps> = ({ onSend }) => {
  const [isSending, setIsSending] = useState(false);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    try {
      event.preventDefault();
      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);
      const data: NotificationData = {
        title: formData.get('title') as string,
        message: formData.get('message') as string,
      };
      const link = formData.get('link') as string;
      if (link) data.link = link;
      setIsSending(true);
      await onSend(data);
      form.reset();
    } catch (error) {
      console.error(error);
      showNotification({
        level: NotificationLevel.ERROR,
        message: 'Error sending notification',
      });
    } finally {
      setIsSending(false);
    }
  }

  return (
    <form onSubmit={onSubmit} className="u-padding--huge">
      <h2>Send manual notification to all users</h2>
      <input type="text" name="title" placeholder="Notification title*" required />
      <textarea name="message" required placeholder="Notification message*"></textarea>
      <input type="text" name="link" placeholder="Link (https)" pattern="https://.*" />
      <button className="e-button e-button--tertiary" disabled={isSending}>
        {isSending ? 'Sending...' : 'Send'}
      </button>
    </form>
  );
};
