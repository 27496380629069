import { format, isValid } from 'date-fns';
import { DATE_FORMAT_LONG_COMA } from '../../../constants';
import { getDifferenceBetweenDays } from '../../../helpers/utils';
import { SortDirection, Column, ColumnType, GridConfiguration, Row } from '..';
import { isNumber } from '../../../helpers/utils';
import { ColumnDef } from '../../../models';
import { AbsoluteOrRelative } from '../../../views/analytics/use-absolute-or-relative-comparison';

export const swapSortDirection = (sort: SortDirection) =>
  sort === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;

export const isColumnAlignedToTheLeft = (
  columns: Column[],
  numStickyColumns: number,
  numColumnsLeftAlignment: number,
  index: number,
): boolean => {
  return (
    (columns[index].type !== ColumnType.CHECKBOX &&
      columns.slice(0, index).map(columnTmp => columnTmp.type !== ColumnType.CHECKBOX).length <
        numStickyColumns) ||
    index === 0 ||
    index < numColumnsLeftAlignment
  );
};

export const getFormattedDate = (value: string) => {
  const parseDate = (dateStr: string) => new Date(dateStr);
  const isValidDate = (date: Date) => isValid(date);
  const formatDate = (dateStr: string) => format(dateStr, DATE_FORMAT_LONG_COMA);

  const date = parseDate(value);

  if (!isValidDate(date) && !value.includes(' - ')) return value;

  if (isValidDate(date)) return formatDate(value);

  const [firstDateStr, secondDateStr] = value.split(' - ');
  const firstDate = parseDate(firstDateStr);
  const secondDate = parseDate(secondDateStr);

  if (!isValidDate(firstDate) && !isValidDate(secondDate)) return value;

  const formattedFirstDate = formatDate(firstDateStr);
  const formattedSecondDate = formatDate(secondDateStr);

  const noOfDays = Math.abs(getDifferenceBetweenDays(secondDate, firstDate)) + 1;

  return `${formattedFirstDate} - ${formattedSecondDate} (${noOfDays}d)`;
};

export const DATE_COLUMNS = ['date2', 'name2', 'pos2', 'p_v_a_date', 'sl_tp_date'];
export const isColumnType = (column: Column, type: ColumnType) => column.type === type;

export const formatNumber = (
  configuration: GridConfiguration,
  rawNumber?: unknown,
  style?: string,
  options?: Partial<Intl.NumberFormatOptions>,
) =>
  Number(rawNumber).toLocaleString(configuration.locale, {
    style,
    currency: configuration.currency.code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  });

export const formatNumericalValues = (
  numericalValue: number,
  column: Column,
  configuration: GridConfiguration,
  columnType?: string,
) => {
  const formatOptions = { minimumFractionDigits: 0 };
  switch (true) {
    case isColumnType(column, ColumnType.CURRENCY):
      return formatNumber(configuration, numericalValue, 'currency');

    case isColumnType(column, ColumnType.PERCENTAGE):
      if (columnType === 'pp')
        return `${formatNumber(configuration, numericalValue * 100, 'decimal', formatOptions)} pp`;
      return formatNumber(configuration, numericalValue, 'percent', formatOptions);

    case isColumnType(column, ColumnType.INT):
      return formatNumber(configuration, numericalValue.toString(), 'decimal', formatOptions);

    case isColumnType(column, ColumnType.FLOAT):
      return formatNumber(configuration, numericalValue.toString(), 'decimal');

    default:
      return numericalValue;
  }
};

export const getComparedRows = (
  rows: Row[],
  columns: ColumnDef[],
  activeTrend: AbsoluteOrRelative,
) => {
  const [firstRow, secondRow] = rows;

  const isComparisonNotPossible =
    Object.keys(firstRow).length === 1 || Object.keys(secondRow).length === 1;

  if (isComparisonNotPossible) return rows;

  const comparisonRow = Object.keys(firstRow).reduce((acc: any, key) => {
    const isDateKey = typeof key === 'string' && key.includes('date');

    const column = columns.find(
      cl =>
        cl.key === key &&
        !cl.isHidden &&
        key !== 'id' &&
        key !== 'pid' &&
        !isDateKey &&
        (cl.type === 'int' || cl.type === 'float'),
    );

    const comparedValue = getComparedValue(firstRow[key], secondRow[key], activeTrend);

    const isPositiveTrend = determinePositiveTrend(comparedValue, key, firstRow[key]);

    if (column && isNumber(firstRow[key]) && isNumber(secondRow[key])) {
      let columnType = 'relative';

      if (activeTrend === 'absolute') {
        columnType = column.wrapper === '%' ? 'pp' : column.wrapper;
      }
      acc[key] = {
        value: firstRow[key],
        comparisonValue: comparedValue,
        columnType: columnType,
        isPositiveTrend: isPositiveTrend,
        columnCellType: 'trend',
      };
    } else {
      acc[key] = firstRow[key];
    }
    return acc;
  }, {});

  return [comparisonRow, secondRow];
};

export const getAbsoluteTrend = (firstNum: number, secondNum: number) => firstNum - secondNum;

export const getRelativeTrend = (firstNum: number, secondNum: number) =>
  (firstNum - secondNum) / Math.abs(secondNum);

export const invertedTrendColumns = [
  'cost2',
  'avg_cpc2',
  'outbid_rate2',
  'hv_lose_share2',
  'hv_unavailability_share2',
  'cpa2',
  'unavailability_share2',
  'lose_share2',
  'avg_cpc_pos',
];

const getComparedValue = (firstNum: number, secondNum: number, activeTrend: AbsoluteOrRelative) => {
  if (firstNum === 0 && secondNum === 0) return 0;
  if (secondNum === 0) return '-';
  if (activeTrend === 'absolute') return getAbsoluteTrend(firstNum, secondNum);
  return getRelativeTrend(firstNum, secondNum);
};

const determinePositiveTrend = (comparedValue: string | number, key: string, firstNum: number) => {
  if (typeof comparedValue === 'number')
    return invertedTrendColumns.includes(key) ? comparedValue < 0 : comparedValue > 0;

  return invertedTrendColumns.includes(key) ? firstNum < 0 : firstNum > 0;
};
