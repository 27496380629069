import React, { useCallback, useRef, useState } from 'react';
import { AsyncSelectDropdownProps, Props, SelectOption } from './select-dropdown.types';
import Select, { ClassNamesConfig, GroupBase } from 'react-select';
import cn from 'classnames';
import './select-dropdown.css';
import AsyncSelect from 'react-select/async';
import { allCustomComponents } from './all-custom-components';
import { debounce } from 'lodash';

export const SelectDropdown = <T extends any>(props: Props<T>) => {
  const {
    options,
    className,
    disabled,
    onChange,
    defaultValue = null,
    required,
    placeholder = 'Please select',
    isSearchable = false,
    noOptionsMessage = 'No results were found',
    resetOnSelect = false,
    isAsync = false,
    loadOptions,
  } = props;
  const [selectedValue, setSelectedValue] = useState<SelectOption<T> | null>(defaultValue);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleChange = (selectedOption: SelectOption<T> | null) => {
    setSelectedValue(selectedOption);
    onChange?.(selectedOption);

    if (resetOnSelect || isAsync) {
      setSelectedValue(null);
    }
  };
  const customComponent = isSearchable
    ? {
        DropdownIndicator: allCustomComponents.DropdownIndicator,
        Input: allCustomComponents.Input,
      }
    : undefined;

  const handleMenuClose = useCallback(() => {
    if (selectRef.current) {
      const input = selectRef.current.querySelector('.react-select-input');
      if (input) {
        (input as HTMLInputElement | HTMLSelectElement).blur();
      }
    }
  }, [selectRef.current]);

  const selectDropdown = () => {
    if (isAsync) {
      return (
        <AsyncSelectDropdown
          handleChange={handleChange}
          defaultOptions={options}
          loadOptions={loadOptions}
          placeholder={placeholder}
          disabled={disabled}
          onMenuClose={handleMenuClose}
        />
      );
    }

    return (
      <Select
        onMenuClose={handleMenuClose}
        blurInputOnSelect
        closeMenuOnSelect
        components={customComponent}
        value={selectedValue}
        onChange={handleChange}
        name="selectDropdown"
        isDisabled={disabled}
        data-testid="selectDropdown"
        classNamePrefix={prefix}
        options={options ?? []}
        isLoading={options === undefined}
        className={cn('c-select-dropdown', className)}
        isClearable={false}
        isSearchable={isSearchable}
        required={required}
        placeholder={placeholder}
        openMenuOnClick={!isSearchable}
        noOptionsMessage={() => noOptionsMessage}
        classNames={selectDropdownClassNames}
      />
    );
  };

  return <div ref={selectRef}>{selectDropdown()}</div>;
};

const AsyncSelectDropdown = <T extends any>(props: AsyncSelectDropdownProps<T>) => {
  const { handleChange, defaultOptions, loadOptions, placeholder, disabled, onMenuClose } = props;
  const [selectedOption, setSelectedOption] = useState<T | null>(null);

  const getOptions = async (inputValue: string) => {
    if (!loadOptions) return [];
    const response: SelectOption<T>[] = await loadOptions(inputValue);

    if (!response) return [];

    return response;
  };

  const debounceGetOptions = useCallback(
    debounce((inputValue: string, callback: Function) => {
      getOptions(inputValue).then(options => callback(options));
    }, 1000),
    [],
  );

  const handleSelectOption = (option: SelectOption<T>) => {
    handleChange(option);
    setSelectedOption(null);
  };
  return (
    <AsyncSelect
      // uncomment next line once following issue is fixed https://github.com/JedWatson/react-select/issues/4645
      // cacheOptions
      components={allCustomComponents}
      defaultOptions={defaultOptions ?? []}
      isLoading={defaultOptions === undefined}
      loadOptions={debounceGetOptions}
      value={selectedOption}
      openMenuOnClick={defaultOptions !== null}
      placeholder={placeholder}
      onChange={handleSelectOption}
      isDisabled={disabled}
      classNames={selectDropdownClassNames}
      onMenuClose={onMenuClose}
    />
  );
};
const prefix = 'c-select-dropdown';
const dropdownIndicatorClass = `${prefix}--indicator-search`;

const selectDropdownClassNames: ClassNamesConfig<unknown, boolean, GroupBase<unknown>> = {
  container: () => `${prefix}--container`,
  control: ({ isFocused }) => `${prefix}--control${isFocused ? '-focused' : ''}`,
  indicatorSeparator: () => `u-display--none`,
  dropdownIndicator: ({ isFocused }) => `${dropdownIndicatorClass}${isFocused ? '-focused' : ''}`,
  valueContainer: () => `${prefix}--value-container`,
  singleValue: () => `${prefix}--single-value`,
  menu: () => `${prefix}--menu`,
  menuList: () => `${prefix}--menu-list`,
  option: ({ isFocused, isSelected }) =>
    `${prefix}--option${isFocused || isSelected ? '-focused' : ''}`,
  placeholder: () => `${prefix}--placeholder`,
  input: () => `${prefix}--input`,
  loadingIndicator: () => `${prefix}--loading-indicator`,
};
