import cn from 'classnames';
import { CSSProperties, FC, useState } from 'react';
import { Column, ColumnBase, ColumnType, NestedRow, TextColumn } from '..';
import { NO_OF_NARROW_TABLE_COLUMNS } from '../../../constants';
import { Row } from '../../../models';
import { GridCell } from '../grid-cell';
import { GridTrendCell } from '../grid-trend-cell';
import { useStickyColumn } from '../hooks/use-sticky-column';
import { isColumnAlignedToTheLeft } from '../utils/grid-utils';
import styles from './grid-row.module.css';
import { ConditionalStyledRow, GridRowProps } from './grid-row.types';

export const GridRow: FC<GridRowProps> = props => {
  const {
    columns,
    row,
    numStickyColumns,
    numColumnsLeftAlignment,
    isSelected,
    isSelectable,
    configuration,
    onSelectRow,
    rowStyles,
    qaId,
    isAccordionTable,
    nestingLevel = 1,
    rowHeader,
    onExpand,
  } = props;
  const { refRow, getCustomColumnStyle, isColumnSticky } = useStickyColumn(
    columns,
    numStickyColumns,
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const isAccordionRow = isAccordionTable && !!row.nested;
  const hasCheckboxColumn = columns.some(column => column.type === ColumnType.CHECKBOX);
  const rowHeaderCellIndex = hasCheckboxColumn ? 1 : 0;

  const clickCell = (column: Column) => {
    if (column.type === ColumnType.CHECKBOX && isSelectable && nestingLevel === 1) onSelectRow?.();
  };
  const getValue = (column: Column): string | boolean | undefined => {
    if (column.type === ColumnType.CHECKBOX) {
      return isSelectable ? isSelected : undefined;
    }
    return row[column.key];
  };

  const buildDataQa = (column: Column) =>
    column.type === ColumnType.CHECKBOX
      ? `${qaId}-checkbox`
      : `${qaId}-${(column as ColumnBase).key}`;

  const getColumnStyle = (index: number): CSSProperties => {
    const style =
      isColumnSticky(index) && columns.length >= NO_OF_NARROW_TABLE_COLUMNS
        ? getCustomColumnStyle(index)
        : undefined;
    return { ...style, paddingLeft: getPaddingLeft(index) };
  };

  const getPaddingLeft = (index: number) => {
    const correction = nestingLevel > 1 && !row.nested ? 10 : 0;
    const isItSticky = columns.length > 0 && index < numStickyColumns;
    return (isItSticky ? nestingLevel * 16 : 16) + correction;
  };

  const isAccordionCell = (index: number) => index === rowHeaderCellIndex && isAccordionRow;

  const isRowHeaderCellSpan = (index: number) => rowHeader !== undefined && isColumnSticky(index);

  const isMergedRowHeaderCell = (index: number) =>
    isRowHeaderCellSpan(index) && index > rowHeaderCellIndex;

  const isRowHeaderCell = (index: number) =>
    isRowHeaderCellSpan(index) && index === rowHeaderCellIndex;

  const rowHeaderCellColSpan = numStickyColumns - rowHeaderCellIndex;

  const rowHeaderColumn = (column: Column) =>
    ({ ...column, type: ColumnType.TEXT, isClickable: false }) as TextColumn;

  const rowStylesToApply = styleClassesToApply(rowStyles ?? [], row).map(
    className => styles[className],
  );

  const LEFT_POSITION = '392px';

  const updateAccordionStickyLeftPosition = () => {
    if (!refRow.current) return;

    const tableRows = refRow.current.parentElement?.children;
    if (!tableRows) return;

    const tableElement = refRow.current.parentElement?.parentElement;
    const allTableHeaderCols = tableElement?.children[0].children[0].children;
    if (!allTableHeaderCols) return;

    const stickyHeaderCols = Array.from(allTableHeaderCols).filter(child =>
      child.classList.value.includes('grid-header_sticky'),
    );

    if (stickyHeaderCols.length < 2) return;

    const lastStickyHeaderCol = stickyHeaderCols[stickyHeaderCols.length - 1];
    if (lastStickyHeaderCol instanceof HTMLElement) {
      lastStickyHeaderCol.style.left = LEFT_POSITION;
    }

    Array.from(tableRows).forEach(tableRow => {
      if (!tableRow.classList.contains(styles.accordion)) return;
      Array.from(tableRow.children).forEach(col => {
        if (col.classList.contains(styles.lastStickyColumn) && col instanceof HTMLElement) {
          col.style.left = LEFT_POSITION;
        }
      });
    });
  };

  const renderGridCell = (columnTmp: Column, index: number) => {
    if (isMergedRowHeaderCell(index)) return null;
    return (
      <td
        key={index}
        title={isColumnSticky(index) ? getValue(columnTmp)?.toString() : undefined}
        style={getColumnStyle(index)}
        className={cn(
          styles.cell,
          ...rowStylesToApply,
          {
            [styles.sticky]: isColumnSticky(index),
            [styles.rightAlignedColumn]: !isColumnAlignedToTheLeft(
              columns,
              numStickyColumns,
              numColumnsLeftAlignment,
              index,
            ),
            [styles.lastStickyColumn]: index === numStickyColumns - 1 || isRowHeaderCell(index),
            [styles.checkbox]: columnTmp.type === ColumnType.CHECKBOX,
          },
          (columnTmp as ColumnBase).cellClassName,
        )}
        data-qa={buildDataQa(columnTmp)}
        colSpan={isRowHeaderCell(index) ? rowHeaderCellColSpan : 1}
      >
        {row[columnTmp.key]?.columnCellType === 'trend' ? (
          <>
            <GridTrendCell
              configuration={configuration}
              value={{
                comparisonValue: row[columnTmp.key].comparisonValue,
                columnType: row[columnTmp.key].columnType,
                isPositiveTrend: row[columnTmp.key].isPositiveTrend,
              }}
              row={row}
              column={isRowHeaderCell(index) ? rowHeaderColumn(columnTmp) : columnTmp}
            />
            <GridCell
              configuration={configuration}
              value={isRowHeaderCell(index) ? rowHeader : row[columnTmp.key].value}
              row={row}
              column={isRowHeaderCell(index) ? rowHeaderColumn(columnTmp) : columnTmp}
              onClickCell={() => clickCell(columnTmp)}
              isAccordion={isAccordionCell(index)}
              isExpanded={isExpanded}
              nestingLevel={nestingLevel}
            />
          </>
        ) : (
          <GridCell
            configuration={configuration}
            value={isRowHeaderCell(index) ? rowHeader : getValue(columnTmp)}
            row={row}
            column={isRowHeaderCell(index) ? rowHeaderColumn(columnTmp) : columnTmp}
            onClickCell={() => clickCell(columnTmp)}
            isAccordion={isAccordionCell(index)}
            isExpanded={isExpanded}
            nestingLevel={nestingLevel}
          />
        )}
      </td>
    );
  };

  const renderNestedGridRow = (nestedRow: NestedRow, index: number) => (
    <GridRow
      {...props}
      row={nestedRow}
      key={index}
      nestingLevel={nestingLevel + 1}
      isSelectable={false}
      rowHeader={nestedRow.rowHeader}
    />
  );

  return (
    <>
      <tr
        ref={refRow}
        className={cn(styles.gridRow, {
          [styles.selected]: isSelected,
          [styles.accordion]: isAccordionRow && nestingLevel === 1,
        })}
        onClick={() => {
          if (!isAccordionTable) return;
          const newExpanded = !isExpanded;
          setIsExpanded(newExpanded);
          updateAccordionStickyLeftPosition();
          onExpand?.(newExpanded);
        }}
      >
        {columns.map(renderGridCell)}
      </tr>
      {isExpanded && <>{row.nested?.map(renderNestedGridRow)}</>}
    </>
  );
};

const styleClassesToApply = (rowStyles: ConditionalStyledRow[], row: Row) =>
  rowStyles.filter(rowStyle => rowStyle.condition?.(row) ?? true).map(({ style }) => style);
