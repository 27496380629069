import React, { FC, FormEvent, useRef, useState } from 'react';
import { NotificationLevel } from '../../../constants';
import { showNotification } from '../../../services/notification-service';
import { useBanner } from '../../../hooks/use-banner';
import { SelectDropdown, SelectOption } from '../../../components/select-dropdown';
import './banner-configurator.css';
import { Banner } from '../../../helpers/api/banner/types';

export const BannerConfigurator: FC = () => {
  const { banner, enable, disable, isUpdating } = useBanner();
  const titleRef = useRef<HTMLInputElement>(null);
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const [selectedTypeOption, setSelectedTypeOption] = useState<SelectOption<NotificationLevel>>();
  const [selectedExpiresInOption, setSelectedExpiresInOption] =
    useState<SelectOption<number>>(defaultDayOption);

  React.useEffect(() => {
    if (!banner) return;

    setSelectedTypeOption(getTypeOption(banner));
    setSelectedExpiresInOption(getDayOption(banner) ?? defaultDayOption);
  }, [banner]);

  async function update() {
    const newTitle = titleRef.current?.value;
    const newBody = bodyRef.current?.value;
    if (!newTitle || !newBody || !selectedTypeOption || !selectedExpiresInOption) {
      showNotification({
        level: NotificationLevel.ERROR,
        message: 'All fields are required',
      });
      return;
    }

    enable({
      header: newTitle,
      body: newBody,
      type: selectedTypeOption.value,
      expiresInSec: selectedExpiresInOption.value,
    });
  }

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    void update();
  }

  const handleDayChange = (option: SelectOption<number>) => setSelectedExpiresInOption(option);
  const handleTypeChange = (option: SelectOption<NotificationLevel>) =>
    setSelectedTypeOption(option);

  const labeledDropdown = <T extends any>(
    label: string,
    options: SelectOption<T>[],
    onChange: (option: SelectOption<T>) => void,
    defaultOption?: SelectOption<T>,
  ) => {
    return (
      <label key={`${label}-${defaultOption?.label ?? 'empty'}`} className="label">
        {label}
        <SelectDropdown
          options={options}
          onChange={onChange}
          required
          className="dropdown"
          defaultValue={defaultOption}
        />
      </label>
    );
  };

  const button = (
    key: string,
    text: string,
    buttonType: 'button' | 'submit',
    onClick?: () => void,
  ) => (
    <button
      key={key}
      disabled={isUpdating}
      type={buttonType}
      onClick={onClick}
      className={buttonClasses}
    >
      {text}
    </button>
  );

  return (
    <div className="banner-configurator">
      <h2>Banners configuration</h2>
      <h3>Main banner ({banner ? 'Active' : 'Not active'})</h3>
      <form onSubmit={onSubmit}>
        <input ref={titleRef} type="text" defaultValue={banner?.header} required />
        <textarea ref={bodyRef} cols={30} rows={10} defaultValue={banner?.body} required></textarea>
        <div className="dropdown-container">
          {labeledDropdown('Expires in:', dayOptions, handleDayChange, selectedExpiresInOption)}
          {labeledDropdown('Type:', typeOptions, handleTypeChange, selectedTypeOption)}
        </div>
        <div>
          {banner
            ? [
                button('deactivate-btn', 'Deactivate', 'button', disable),
                button('update-btn', isUpdating ? 'Updating...' : 'Update', 'submit'),
              ]
            : button('activate-btn', isUpdating ? 'Activating...' : 'Activate', 'submit')}
        </div>
      </form>
    </div>
  );
};

const buttonClasses = 'e-button e-button--small e-button--tertiary u-margin-top--small';

const MAX_DAYS = 10;
const dayOption = (days: number): SelectOption<number> => ({
  value: days * 24 * 60 * 60,
  label: `${days} days`,
});
const dayOptions = Array.from({ length: MAX_DAYS }, (_, i) => dayOption(i + 1));
const defaultDayOption = dayOptions[1];
const getDayOption = (banner: Banner): SelectOption<number> | undefined =>
  dayOptions.find(option => option.value === banner.expiresInSec);

const typeOption = (typeToConvert: NotificationLevel): SelectOption<NotificationLevel> => ({
  value: typeToConvert,
  label: typeToConvert,
});
const typeOptions = Object.values(NotificationLevel).map(typeOption);
const getTypeOption = (banner: Banner): SelectOption<NotificationLevel> | undefined =>
  typeOptions.find(option => option.value === banner.type);
