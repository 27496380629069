import React, { FC } from 'react';
import cn from 'classnames';
import { Modal } from '../modal/modal';
import { Button } from '../button/button';
import styles from './upload-inventory-modal.module.css';
import { Props, TitleProps, CTAProps, BodyProps } from './upload-inventory-modal.types';
import { TertiaryButton } from '../tertiary-button';

export const UploadInventoryModal: FC<Props> = ({
  onCloseModal,
  onClickCTA,
  title,
  children,
  titleStyle,
  bodyStyle,
  ctaButtonStyle,
  tertiaryButtonStyle,
  variant = 'withHeader',
  ctaButtonTitle,
  tertiaryButtonTitle = 'Cancel',
}) => {
  const ModalComponent = MODAL_VARIANTS[variant];
  return (
    <Modal
      onClose={() => {
        onCloseModal();
      }}
      backdropStatic
      className={styles.modalSize}
    >
      <div className={styles.inventoryFileUploadModal}>
        <ModalComponent
          onCloseModal={onCloseModal}
          onClickCTA={onClickCTA}
          title={title}
          titleStyle={titleStyle}
          bodyStyle={bodyStyle}
          ctaButtonStyle={ctaButtonStyle}
          ctaButtonTitle={ctaButtonTitle}
          tertiaryButtonTitle={tertiaryButtonTitle}
          tertiaryButtonStyle={tertiaryButtonStyle}
        >
          {children}
        </ModalComponent>
      </div>
    </Modal>
  );
};

const ModalHeader: React.FunctionComponent<TitleProps> = ({ title, titleStyle }) => (
  <div className={cn(styles.inventoryFileUploadModalHeader, titleStyle)}>
    <h1>{title}</h1>
  </div>
);

const ModalBody: FC<BodyProps> = ({ children, bodyStyle, isWithHeader }) => (
  <div className={cn(styles.borderBottom, bodyStyle)} data-testid="uploadInventory">
    <div
      className={cn(
        isWithHeader
          ? styles.inventoryFileUploadModalBody
          : styles.withoutHeaderInventoryFileUploadModalBody,
      )}
    >
      {children}
    </div>
  </div>
);
const WithHeaderModal: FC<Props> = ({
  onCloseModal,
  onClickCTA,
  title,
  children,
  titleStyle,
  bodyStyle,
  ctaButtonTitle,
  tertiaryButtonTitle,
  ctaButtonStyle,
  tertiaryButtonStyle,
}) => (
  <>
    {title && <ModalHeader title={title} titleStyle={titleStyle} />}
    <ModalBody bodyStyle={cn(bodyStyle)} isWithHeader={!!title}>
      {children}
    </ModalBody>
    <WithHeaderModalCTA
      onCloseModal={onCloseModal}
      onClickCTA={onClickCTA}
      ctaButtonTitle={ctaButtonTitle}
      tertiaryButtonTitle={tertiaryButtonTitle}
      tertiaryButtonStyle={tertiaryButtonStyle}
      ctaButtonStyle={ctaButtonStyle}
    />
  </>
);

const WithoutHeaderModal: FC<Props> = ({
  onCloseModal,
  onClickCTA,
  children,
  bodyStyle,
  ctaButtonStyle,
  ctaButtonTitle,
  tertiaryButtonTitle,
  tertiaryButtonStyle,
}) => (
  <>
    <ModalBody bodyStyle={cn(bodyStyle)}>{children}</ModalBody>
    <WithoutHeaderModalCTA
      onCloseModal={onCloseModal}
      onClickCTA={onClickCTA}
      ctaButtonStyle={ctaButtonStyle}
      ctaButtonTitle={ctaButtonTitle}
      tertiaryButtonTitle={tertiaryButtonTitle}
      tertiaryButtonStyle={tertiaryButtonStyle}
    />
  </>
);

const WithHeaderModalCTA: FC<CTAProps> = ({
  onCloseModal,
  onClickCTA,
  ctaButtonTitle,
  tertiaryButtonTitle,
  ctaButtonStyle,
  tertiaryButtonStyle,
}) => (
  <div className={styles.cta}>
    <Button message={ctaButtonTitle} onClick={onClickCTA} className={cn(ctaButtonStyle)} />
    <TertiaryButton onClick={onCloseModal} className={cn(tertiaryButtonStyle)}>
      {tertiaryButtonTitle}
    </TertiaryButton>
  </div>
);

const WithoutHeaderModalCTA: FC<CTAProps> = ({
  onCloseModal,
  onClickCTA,
  ctaButtonTitle,
  tertiaryButtonTitle,
  ctaButtonStyle,
  tertiaryButtonStyle,
}) => (
  <div className={styles.cta}>
    {onClickCTA && (
      <Button message={ctaButtonTitle} onClick={onClickCTA} className={ctaButtonStyle} />
    )}
    {onCloseModal && (
      <TertiaryButton onClick={onCloseModal} className={cn(tertiaryButtonStyle)}>
        {tertiaryButtonTitle}
      </TertiaryButton>
    )}
  </div>
);

const MODAL_VARIANTS = {
  withHeader: WithHeaderModal,
  withoutHeader: WithoutHeaderModal,
};
