import {
  useRef,
  useState,
  useEffect,
  KeyboardEvent,
  FunctionComponent,
  MutableRefObject,
  useContext,
} from 'react';
import NumberFormat from 'react-number-format';
import { BMControlPanelProps } from './bm-control-panel.types';
import cs from 'classnames';
import './bm-control-panel.css';
import IconClose from './icn-close.svg';
import IconLock from './icn-lock-line-dark.svg';
import { WrapperErrorHint } from '../../../components/wrapper-error-hint';
import { BidModifier, BaseNotification, PerformedBids, View } from '../../../models';
import {
  KEYBOARD_EVENT_ENTER,
  VIEW_TITLE_KEY,
  NotificationLevel,
  BIDDING_BID_MODIFIERS_SINGLE_BREAKOUT_APPLIED,
  BIDDING_BID_MODIFIERS_MULTIPLE_BREAKOUTS_APPLIED,
} from '../../../constants';
import { TranslationKey, translate } from '../../../helpers/utils';
import { AppContext } from '../../../global/context/app-context';
import { usePropertyGroups } from '../../../hooks/use-property-groups';
import {
  areMultipleBrandsSelected,
  areMultiplePOSorGlobalSelected,
  getPartner,
} from '../../../services/app-service';
import { applyBidModifiers } from '../../../services/bidding-service';
import { showNotification } from '../../../services/notification-service';
import TrackerService from '../../../services/tracker/tracker-service';

import {
  URL_APPLY_BID_MODIFIERS_CPA,
  URL_APPLY_BID_MODIFIERS_CPC,
} from '../../../helpers/api/bidding/api-bidding-helper';
import { useFilters } from '../../../global/context/filter-context';

export const BmControlPanel: FunctionComponent<BMControlPanelProps> = ({
  bidModifiers,
  propertyGroups: selectedPropertyGroupIds,
  view,
  validations: { maxValue, minValue },
  onBidModifiersApplied,
  onClose,
  bmRemainingPushes,
}) => {
  const { partner, subPartners, selectedLocales } = useContext(AppContext);
  const [value, setValue] = useState(
    bidModifiers.length === 1 ? String(bidModifiers[0].value) : '',
  );
  const [isApplyingChanges, setIsApplyingChanges] = useState(false);
  const inputRef: MutableRefObject<HTMLInputElement | undefined> = useRef(undefined);
  const allPropertyGroups = usePropertyGroups();
  const { selectedBiddingTypes } = useFilters();

  useEffect(() => {
    setValue(bidModifiers.length === 1 ? String(bidModifiers[0].value) : '');
    onClickInput();
  }, [bidModifiers]);

  const localeCode = selectedLocales[0];
  const isMultipleSelection = bidModifiers.length > 1;
  const isAllBreakoutPending = bidModifiers.every(b => b.pendingStatus);
  const bidModifier: BidModifier =
    bidModifiers.length === 1
      ? bidModifiers[0]
      : { field: '', value: 0.0, breakoutId: -1, dimensionId: '', pendingStatus: false };
  let inputPlaceholder = '';
  if (value === '') {
    inputPlaceholder = isMultipleSelection
      ? translate('bm_control_panel_placeholder_multiple')
      : '0.00';
  }
  const isAllPropertyGroupsSelected = selectedPropertyGroupIds.length === allPropertyGroups.length;

  const isManualBiddingTypeSelected =
    selectedBiddingTypes && selectedBiddingTypes.includes('Manual');

  const hasBothBiddingTypes =
    selectedBiddingTypes &&
    selectedBiddingTypes.length > 1 &&
    selectedBiddingTypes.includes('Manual');

  const isCPABiddingForNonTTTView =
    view !== View.BM_POS_TIME_TO_TRAVEL && !isManualBiddingTypeSelected;

  const isControlPanelBlocked = (): { result: boolean; reason: string } => {
    const isInvalidSelection =
      areMultiplePOSorGlobalSelected(Array.from(selectedLocales)) ||
      areMultipleBrandsSelected(Array.from(subPartners)) ||
      hasBothBiddingTypes;

    return {
      result: isInvalidSelection || isCPABiddingForNonTTTView,
      reason: isCPABiddingForNonTTTView
        ? translate('bm_control_panel_blocked_message_cpa_only_no_ttt')
        : translate('bm_control_panel_blocked_message'),
    };
  };

  const trackApplyBidModifiers = () => {
    const event = isMultipleSelection
      ? BIDDING_BID_MODIFIERS_MULTIPLE_BREAKOUTS_APPLIED(view)
      : BIDDING_BID_MODIFIERS_SINGLE_BREAKOUT_APPLIED(view);

    const properties = !isManualBiddingTypeSelected
      ? { bidModifiers, localeCode }
      : { bidModifiers, localeCode, propertyGroups: selectedPropertyGroupIds };

    TrackerService.track(event, { bidModifiersProperties: properties });
  };

  const bidModifierURL = isManualBiddingTypeSelected
    ? URL_APPLY_BID_MODIFIERS_CPC
    : URL_APPLY_BID_MODIFIERS_CPA;

  // Events
  const onApply = async () => {
    try {
      setIsApplyingChanges(true);
      const partnerIdSelected = getPartner(partner, subPartners).partnerId;

      trackApplyBidModifiers();

      const response: PerformedBids = await applyBidModifiers(
        bidModifiers,
        selectedPropertyGroupIds,
        view,
        partnerIdSelected,
        localeCode,
        +value!,
        bidModifierURL,
      );
      setIsApplyingChanges(false);
      showNotifications(response);
    } catch (e) {
      setIsApplyingChanges(false);
      showNotification({
        message: translate('bm_control_panel_notification_message_error'),
        level: NotificationLevel.ERROR,
      });
    }
  };

  const showNotifications = (response: PerformedBids) => {
    const notification: BaseNotification = {
      level: NotificationLevel.ERROR,
      message: 'analytics_perform_bids_error',
    };

    if (response.updatedBids === 0) {
      notification.message = `${translate('common_fail')} ${translate(
        'analytics_perform_bids_error',
      )}`;
    } else if (response.updatedBids >= response.totalBids) {
      notification.level = NotificationLevel.SUCCESS;
      notification.message = translate(
        'bm_control_panel_notification_message_success',
        String(response.totalBids),
      );
    } else {
      notification.level = NotificationLevel.WARNING;
      notification.message = `${translate('notification_partial_success')} ${translate(
        'bm_control_panel_notification_message_partial_success',
      )} ${response.updatedBids} ${translate('common_of_the')} ${response.totalBids} ${translate(
        'bm_control_panel_selected_bid_modifiers',
      )} ${translate('analytics_check_status')}`;
    }

    showNotification(notification);
    onBidModifiersApplied();
  };

  const onClickInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.select();
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYBOARD_EVENT_ENTER && !isInvalidInput()) {
      onApply();
    }
  };

  const onValueChange = (valueInput: string) => {
    setValue(valueInput);
  };

  const isInvalidInput = () => {
    const valueInputNum = +value;
    return value !== '' && (valueInputNum > maxValue || valueInputNum < minValue);
  };

  const isInvalid = isInvalidInput();
  const isBlockedResult = isControlPanelBlocked();

  const fieldPropertyGroupsSelected = () => {
    if (!isManualBiddingTypeSelected) return null;

    const fieldPropertyGroupsSelectedText =
      isAllPropertyGroupsSelected || selectedPropertyGroupIds.length > 1
        ? translate(
            'bm_control_panel__multiple_property_group_selected',
            String(
              isAllPropertyGroupsSelected
                ? allPropertyGroups.length
                : selectedPropertyGroupIds.length,
            ),
          )
        : translate(
            'bm_control_panel__single_property_group_selected',
            String(selectedPropertyGroupIds[0]),
          );
    return (
      <span className="u-font-weight--bold u-margin-right--tiny">
        {fieldPropertyGroupsSelectedText}
      </span>
    );
  };

  return (
    <section className="c-bm-control-panel u-margin-bottom--gutter" data-testid="bmControlPanel">
      <div className="c-bm-control-panel__upper-container">
        <div className="u-display--flex">
          <span className="u-font-weight-bold u-margin-right--tiny">
            {isMultipleSelection ? (
              <>
                <img
                  src={IconClose}
                  className="c-bm-control-panel__icon u-cursor--pointer u-margin-right--medium"
                  onClick={onClose}
                  alt="Close the popup"
                />
                {bidModifiers.length} {translate('bm_control_panel_multiple_breakouts_selected')}
              </>
            ) : (
              `${translate(VIEW_TITLE_KEY[view] as TranslationKey)}: ${bidModifier.field}`
            )}
          </span>
          {fieldPropertyGroupsSelected()}
          {isBlockedResult.result && bmRemainingPushes > 0 ? (
            <>
              <img
                src={IconLock}
                className="c-bm-control-panel__icon u-color--juri u-margin-right--tiny"
                alt="Lock icon showing bid modifiers cannot be edited"
              />
              <span className="u-color--juri-light">{isBlockedResult.reason}</span>
            </>
          ) : (
            <>
              {' - '}
              <span className="c-bm-control-panel__current-value u-margin-left--tiny">
                {isAllBreakoutPending
                  ? translate('bm_control_panel_single_breakout_pending_value')
                  : translate('bm_control_panel_single_breakout_current_value')}
                {isMultipleSelection
                  ? translate('bm_control_panel_placeholder_multiple')
                  : bidModifier.value}
              </span>
            </>
          )}
          {bmRemainingPushes === 0 && (
            <>
              <img
                src={IconLock}
                className="c-bm-control-panel__icon u-color--juri u-margin-right--tiny"
                alt="Lock icon showing bid modifiers cannot be edited"
              />
              <span className="u-color--juri-light">
                {translate('analytics_home_bm_daily_push_limit_message')}
              </span>
            </>
          )}
        </div>

        {!isBlockedResult.result && bmRemainingPushes > 0 && (
          <div className="u-display--flex u-align-items--center">
            <label className="u-font-weight-normal u-margin-right--gutter">
              {translate('bm_control_panel_bid_modifier')}
            </label>
            <WrapperErrorHint
              message={translate('bm_control_panel_bidding_modifier_over_limit')}
              isShown={isInvalid}
            >
              <NumberFormat
                getInputRef={(el: HTMLInputElement) => {
                  if (!inputRef.current) inputRef.current = el;
                }}
                className={cs('c-bm-control-panel__number-input u-margin-right--medium', {
                  'c-bm-control-panel__number-input-error': isInvalid,
                })}
                decimalScale={2}
                decimalSeparator="."
                placeholder={inputPlaceholder}
                allowNegative={false}
                onKeyPress={onKeyPress}
                value={value}
                isNumericString={false}
                onValueChange={v => onValueChange(v.value)}
                onClick={onClickInput}
              />
            </WrapperErrorHint>
            <button
              className="e-button e-button--small e-button--primary"
              type="button"
              disabled={isInvalid || !value || isApplyingChanges}
              onClick={onApply}
            >
              {translate('bm_control_panel_apply')}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};
