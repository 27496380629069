import cn from 'classnames';
import { FC, useContext, useState } from 'react';
import { FILE_EXTENSION, NotificationLevel } from '../../constants';
import { AppContext } from '../../global/context/app-context';
import { getFormattedDates, translate } from '../../helpers/utils';
import { Aggregation } from '../../models';
import { getPartnersId } from '../../services/app-service';
import { showNotification } from '../../services/notification-service';
import ReportService from '../../services/report-service';
import { ButtonDropdown } from '../button-dropdown';
import {
  useCompareDateRangePeriods,
  useDateRangePeriod,
  useIsComparing,
} from '../date-picker/date-picker.state';
import { Loading } from '../loading';
import {
  getFilledFilters,
  getValidFilters,
} from '../toolbar/custom-filters/helpers/validate-filters';
import styles from './view-exporter.module.css';
import { ViewExporterProps } from './view-exporter.types';
import trackerService from '../../services/tracker/tracker-service';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';

export const ViewExporter: FC<ViewExporterProps> = props => {
  const { title, view, columns, aggregation, exportOptions, filters, sort, onError, disabled } =
    props;
  const { partner, subPartners, selectedLocales } = useContext(AppContext);
  const [isComparing] = useIsComparing();
  const [comparePeriods] = useCompareDateRangePeriods();
  const [dateRange] = useDateRangePeriod();
  const [isLoading, setLoading] = useState(false);
  const currentView = useCurrentView();

  function getComparePeriods() {
    if (!isComparing) return [];

    return comparePeriods.map(it => getFormattedDates(it.from, it.to)!);
  }

  const onExport = async (option: number) => {
    const effectivePartnerIds = getPartnersId(partner, Array.from(subPartners));
    const chosenOption = exportOptions[option];
    const fileFormat = chosenOption.fileFormat;
    const fileName =
      chosenOption.fileName !== undefined
        ? chosenOption.fileName + FILE_EXTENSION[fileFormat]
        : undefined;

    const aggregations = [chosenOption.payload?.aggregation ?? aggregation];

    setLoading(true);
    try {
      trackerService.track(`Export Report type on ${currentView} view `, {
        reportType: chosenOption.text,
      });
      await ReportService.export(
        chosenOption.payload?.view ?? view,
        aggregations.filter(agg => agg !== null && agg !== undefined),
        fileFormat,
        {
          partnersId: effectivePartnerIds,
          locales: chosenOption.payload?.locales ?? Array.from(selectedLocales),
          ...(chosenOption.payload?.dateRange ?? dateRange),
          filters: getFilledFilters(
            getValidFilters(chosenOption.payload?.filters ?? filters, columns),
          ),
          sort: chosenOption.payload?.sort ?? sort,
          comparePeriods: getComparePeriods(),
        },
        fileName,
      );
    } catch (e) {
      const error = e as Error;
      if (onError) {
        onError(error.message);
      }
      showNotification({
        level: NotificationLevel.ERROR,
        message: translate((e as any).message),
      });
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <button
        className={cn('e-button e-button--small e-button--tertiary', styles.buttonLoading)}
        data-qa="export-report-button"
      >
        <Loading className="" />
      </button>
    );
  }
  return (
    <ButtonDropdown
      title={title}
      options={exportOptions.map(({ text: field }, idx) => ({ text: field, value: idx }))}
      onSelectOption={onExport}
      qaId="export-report-button"
      disabled={disabled}
    />
  );
};
