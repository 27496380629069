import { Props } from './tertiary-button.types';
import React from 'react';
import './tertiary-button.css';
import cs from 'classnames';

export const TertiaryButton: React.FunctionComponent<Props> = ({
  onClick,
  children,
  className,
}) => {
  return (
    <button
      className={cs(
        'e-button e-button--tertiary e-button--small u-display--flex u-align-items--center',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
