import { FC, useState } from 'react';
import styles from './global-active-filters.module.css';
import { translate } from '../../../helpers/utils';
import { ColumnDef, FilterColumn, FilterType } from '../../../models';
import {
  deleteDimensionFiltersFromLocalStorage,
  getDimensionFiltersFromLocalStorage,
  saveDimensionFiltersToLocalStorage,
} from '../../../helpers/local-storage-helper';
import { getUpdatedFiltersToBeStored } from '../../../services/analytics-service';
import { useSelectFilterSet } from '../custom-filters/hooks/use-select-filter-set';
import { getDefaultFilterSetName } from '../custom-filters/helpers/default-filter-set-name';
import { useFilters } from '../../../global/context/filter-context';
import { getFilledFilters, getValidFilters } from '../custom-filters/helpers/validate-filters';
import { GlobalActiveFiltersProps, PopoverFilterType } from './global-active-filters.types';
import cn from 'classnames';
import { Tag } from '../../../core-ui/components/tag/tag';
import { FilterTag } from '../filter-tag';
import { FEATURE_FLAGS } from '../../../constants';
import { HorizontalScroll } from '../../../core-ui/components/horizontal-scroll/horizontal-scroll';
import { FilterPopover } from './filter-popover';

export const GlobalActiveFilters: FC<GlobalActiveFiltersProps> = ({
  filterStorageKey,
  showActiveFiltersLabel,
}) => {
  const { filters, columns, filterTypes, setSelectedFilter, setFilters } = useFilters();
  const DEFAULT_FILTER_SET_NAME = getDefaultFilterSetName();
  const validFilters = getFilledFilters(getValidFilters(filters, columns));
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [popoverFilter, setPopoverFilter] = useState<PopoverFilterType | undefined>(undefined);

  const { setSelected: setIsSelectedFilterSet, storeSelected } = useSelectFilterSet(
    '',
    filterStorageKey,
  );

  const findTypeName = (condition: string) =>
    filterTypes.find((filterType: FilterType) => filterType.key === condition)?.name || '';
  const findColumn = (filter: FilterColumn) =>
    columns.find((columnTmp: ColumnDef) => columnTmp.key === filter.columnName)!;

  const removeFilter = (filter: FilterColumn) => {
    removeStoredFilter(filter);
    setFilters(
      filters.filter(
        (filterTmp: FilterColumn) =>
          !(filterTmp.columnLabel.toLowerCase() === filter.columnLabel.toLowerCase()),
      ),
    );
    storeSelected(DEFAULT_FILTER_SET_NAME);
  };

  const removeStoredFilter = (filter: FilterColumn) => {
    const filtersStored = getDimensionFiltersFromLocalStorage(filterStorageKey);
    const filtersToAddOrEdit: FilterColumn[] = [];
    const filtersToRemoveFromStore = [filter];
    const updatedStoredFilters = getUpdatedFiltersToBeStored(
      filtersStored,
      filtersToAddOrEdit,
      filtersToRemoveFromStore,
    );
    saveDimensionFiltersToLocalStorage(updatedStoredFilters, filterStorageKey);
  };

  const clearFilters = () => {
    setFilters([]);
    setIsSelectedFilterSet('');
    deleteDimensionFiltersFromLocalStorage(filterStorageKey);
  };

  const handleApplyFilter = (filter: PopoverFilterType) => {
    const newFilters = filters.map((filterTmp: FilterColumn) =>
      filterTmp.columnLabel.toLowerCase() === filter.columnLabel.toLowerCase() ? filter : filterTmp,
    );
    const filtersStored = getDimensionFiltersFromLocalStorage(filterStorageKey);
    const updatedStoredFilters = getUpdatedFiltersToBeStored(filtersStored, newFilters, []);

    saveDimensionFiltersToLocalStorage(updatedStoredFilters, filterStorageKey);
    setFilters(newFilters);
  };

  const tags = validFilters.map((filter: FilterColumn) => {
    const column = findColumn(filter);
    if (!column) return null;

    if (FEATURE_FLAGS.shouldUseNewFilterDesign && filter) {
      const tagContent = (
        <span>
          <b>{filter.columnLabel}</b>
          {` ${findTypeName(filter.type)} `}
          <b>{`${filter.value}  ${column.wrapper}`}</b>
        </span>
      );
      return (
        <Tag
          isSelected
          key={`${filter.columnLabel}-${filter.value}`}
          onDelete={() => removeFilter(filter as PopoverFilterType)}
          onClick={event => {
            event?.stopPropagation();
            setAnchor(event?.currentTarget as HTMLElement);
            setPopoverFilter({
              ...filter,
              columnType: column.type,
              wrapper: column.wrapper,
            } as PopoverFilterType);
          }}
          data-qa={`active-filters-${filter?.columnLabel}`}
        >
          {tagContent}
        </Tag>
      );
    }

    return (
      <FilterTag
        key={`${column.name}-${filter.value}`}
        columnName={column.name}
        typeName={findTypeName(filter.type)}
        value={filter.value}
        wrapper={column.wrapper}
        onRemove={() => removeFilter(filter)}
        onFocus={() => setSelectedFilter(filter)}
      />
    );
  });

  if (tags.length === 0) return null;

  if (FEATURE_FLAGS.shouldUseNewFilterDesign) {
    return (
      <article className={styles.newTags}>
        <HorizontalScroll>
          {showActiveFiltersLabel && (
            <div className={styles.activeFiltersHeader}>
              {translate('analytics_filters_active')}
            </div>
          )}
          {tags}
          {anchor && (
            <FilterPopover
              anchor={anchor}
              setAnchor={setAnchor}
              key={popoverFilter?.columnName}
              selectedFilter={popoverFilter as PopoverFilterType}
              clearFilter={removeFilter}
              applyFilter={handleApplyFilter}
              setSelectedFilter={setPopoverFilter}
            />
          )}
        </HorizontalScroll>
        <div>
          <button
            onClick={clearFilters}
            className={cn(styles.clearFiltersBtn, 'e-button e-button--small e-button--link')}
            data-qa="active-filters-clear"
          >
            {translate('analytics_filters_clear')}
          </button>
        </div>
      </article>
    );
  }

  return (
    <article
      className={cn(styles.tags, {
        [styles.oldFilterMargin]: !FEATURE_FLAGS.shouldUseNewFilterDesign,
      })}
    >
      {showActiveFiltersLabel && (
        <div className={styles.activeFiltersHeader}>{translate('analytics_filters_active')}</div>
      )}
      {tags}
      <button
        onClick={clearFilters}
        className={cn(styles.clearFiltersBtn, 'e-button e-button--small e-button--link')}
        data-qa="active-filters-clear"
      >
        {translate('analytics_filters_clear')}
      </button>
    </article>
  );
};
