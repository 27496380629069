import * as React from 'react';
import CloseIcon from 'tcp-react-icons/lib/CloseIcon';
import './filter-tag.css';
import { Props } from './filter-tag.types';

export const FilterTag: React.FunctionComponent<Props> = ({
  columnName,
  typeName,
  value,
  wrapper,
  qaId,
  onFocus,
  onRemove,
}) => {
  return (
    <div
      className="c-filter-tag u-display--flex u-align-items--center u-margin-right--small"
      data-qa={qaId}
    >
      <CloseIcon
        className="c-filter-tag__icon u-color--juri u-cursor--pointer u-margin-right--small"
        data-qa={`${qaId}-close`}
        onClick={onRemove}
      />
      <p onClick={onFocus} className="u-cursor--pointer">
        <span className="u-font-weight--bold">{columnName}</span>
        {' ' + typeName + ' '}
        <span className="u-margin-right--small u-font-weight--bold">{value + ' ' + wrapper}</span>
      </p>
    </div>
  );
};
