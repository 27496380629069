import { ReactNode } from 'react';

export type Props = {
  message?: string | ReactNode;
  position: TooltipPosition;
  className?: string;
  classNameParent?: string;
  hideTooltip?: boolean;
  isAddingPadding?: boolean;
};

export enum TooltipPosition {
  TOP_CENTER = 'top-center',
  TOP_LEADING = 'top-leading',
  TOP_TRAILING = 'top-trailing',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_LEADING = 'bottom-leading',
  BOTTOM_TRAILING = 'bottom-trailing',
  TRAILING_TOP = 'trailing_top',
  TRAILING_CENTER = 'trailing-center',
  LEADING_CENTER = 'leading_center',
}
