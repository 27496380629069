import * as React from 'react';
import { Props } from './hotel-not-found.types';
import './hotel-not-found.css';
import teapot from './teapot.svg';
import { Card } from '../card';
import { translateHtml, translate } from '../../helpers/utils';
import { Redirect, useLocation } from 'react-router-dom';

export const HotelNotFound: React.FunctionComponent<Props> = ({
  partnerRef,
  redirectRoute,
  reason,
}) => {
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const location = useLocation();
  return (
    <>
      {shouldRedirect && (
        <Redirect to={{ pathname: redirectRoute, state: { from: location.pathname } }} />
      )}
      <Card className="c-hotel-not-found__card u-margin--gutter">
        <div className="u-display--flex u-margin-auto c-hotel-not-found">
          <div className="u-flex-direction--column c-hotel-not-found__left">
            <h1 className="u-margin-bottom--medium u-margin-top--medium">
              {translate('analytics_hotel_not_found_title', partnerRef)}
            </h1>
            <span>{translateHtml(reason)}</span>
            <div>
              <button
                className="e-button e-button--small e-button--primary u-margin-top--gutter"
                onClick={() => setShouldRedirect(true)}
              >
                {translate('analytics_hotel_not_found_back_button')}
              </button>
            </div>
          </div>
          <div className="u-margin-left--small">
            <img src={teapot} width={150} height={150} alt="Teapot icon showing hotel not found" />
          </div>
        </div>
      </Card>
    </>
  );
};
