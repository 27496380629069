import * as React from 'react';
import { Props } from './cpa-input-form.types';
import './cpa-input-form.css';
import { areArraysEqual, translate, translateHtml } from '../../../../helpers/utils';
import { WrapperErrorHint } from '../../../../components/wrapper-error-hint';
import NumberFormat from 'react-number-format';
import cs from 'classnames';
import { TooltipPosition, TooltipWrapper } from '../../../../components/tooltip-wrapper';
import { useEffect, useRef, useState } from 'react';
import { KEYBOARD_EVENT_ENTER } from '../../../../constants';
import { BiddingCampaign } from '../../../../models';

const MAX_CPA_VALUE = 100;

export const CpaInputForm: React.FunctionComponent<Props> = ({ campaigns, onApply }) => {
  const [value, setValue] = useState('');
  const inputRef: React.MutableRefObject<HTMLInputElement | undefined> = useRef(undefined);
  const previousCampaigns = useRef<BiddingCampaign[]>([]);

  const isMultiple = campaigns.length > 1;
  const inputPlaceholder = isMultiple
    ? translate('bidding_control_panel_placeholder_multiple')
    : '0.00 %';

  useEffect(() => onClickInput(), []);

  useEffect(() => {
    setValue(isMultiple ? '' : String(campaigns[0].cpaValue ?? campaigns[0].minCpaValue));

    const areCampaignsEqual = areArraysEqual(
      campaigns,
      previousCampaigns.current,
      (c1, c2) => c1.pos.localeCode === c2.pos.localeCode && c1.type.code === c2.type.code,
    );
    if (inputRef.current && !areCampaignsEqual) {
      inputRef.current.select();
    }
    previousCampaigns.current = campaigns;
  }, [campaigns, isMultiple]);

  const isInvalid = (() => {
    const valueInputNum = +value;
    const minValue = Math.max(...campaigns.map(c => c.minCpaValue));

    return value !== '' && (valueInputNum >= MAX_CPA_VALUE || valueInputNum < minValue);
  })();

  const isButtonDisabled = isInvalid || !value;

  const onClickInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.select();
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYBOARD_EVENT_ENTER && !isInvalid) {
      onApply(+value);
    }
  };
  const onValueChange = (valueInput: string) => {
    setValue(valueInput);
  };

  const getErrorMessageKey = () => {
    if (+value > MAX_CPA_VALUE) {
      return translateHtml('bidding_control_panel_cpa_value_over_limit_max');
    } else if (isMultiple) {
      return translateHtml('bidding_control_panel_cpa_value_over_limit_multiple');
    } else {
      return translateHtml('bidding_control_panel_cpa_value_over_limit_single');
    }
  };

  return (
    <div className="u-display--inline-flex u-align-items--center">
      <label className="u-font-weight-normal u-margin-right--gutter">
        {translate('bidding_control_panel_cpa_value_field')}
      </label>
      <WrapperErrorHint message={getErrorMessageKey()} isShown={isInvalid}>
        <NumberFormat
          getInputRef={(el: HTMLInputElement) => {
            if (!inputRef.current) inputRef.current = el;
          }}
          className={cs('c-cpa-input-form__input u-margin-right--medium', {
            'c-cpa-input-form__input--error': isInvalid,
          })}
          decimalScale={2}
          decimalSeparator="."
          placeholder={inputPlaceholder}
          suffix={'%'}
          allowNegative={false}
          onKeyPress={onKeyPress}
          value={value}
          isNumericString={false}
          onValueChange={v => onValueChange(v.value)}
          onClick={onClickInput}
        />
      </WrapperErrorHint>
      <TooltipWrapper
        message={translate('bidding_control_panel_tooltip')}
        hideTooltip={!isButtonDisabled}
        position={TooltipPosition.TOP_TRAILING}
        noTinyPaddingTop={true}
        className="u-display--inline-block"
      >
        <button
          className="e-button e-button--small e-button--primary"
          type="button"
          disabled={isButtonDisabled}
          onClick={() => onApply(+value)}
        >
          {translate('common_apply')}
        </button>
      </TooltipWrapper>
    </div>
  );
};
