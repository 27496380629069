import * as React from 'react';
import { Props } from './assign-partner-modal-row.types';
import './assign-partner-modal-row.css';
import { Checkbox } from '../../../../../components/checkbox';

export const AssignPartnerModalRow: React.FunctionComponent<Props> = ({
  partner: { partnerId, name },
  checkbox,
}) => {
  return (
    <>
      <td className="u-vertical-align--middle">
        {checkbox ? (
          <Checkbox
            isChecked={checkbox.isChecked}
            title={''}
            onChange={() => checkbox.onChange(partnerId)}
          />
        ) : (
          <span> Already Assigned </span>
        )}
      </td>
      <td className="u-font-weight-bold"> {partnerId} </td>
      <td className="u-font-weight-bold"> {name} </td>
    </>
  );
};
