import React from 'react';
import { useModal } from 'react-modal-hook';
import { FullscreenModal } from '../../../components/fullscreen-modal';
import { RadioButton } from '../../../components/radio-button';
import { Button } from '../../../components/button';
import { DashBoard } from '../../../models';
import { useSnackbar } from '../../../global/app/snackbar/use-snackbar';

interface UseMoveChartModalData {
  chartName: string;
  widgetUuid?: string;
  dashboards: DashBoard[];
}

export function useMoveChartModal(
  onSave: (dashboard: DashBoard) => Promise<void>,
  data: UseMoveChartModalData,
) {
  const [open, close] = useModal(
    () => (
      <MoveChartModal
        chartName={data.chartName}
        onClose={close}
        dashboards={data.dashboards}
        onSave={onSave}
      />
    ),
    [data.chartName, data.dashboards, data.widgetUuid, onSave],
  );

  return { open };
}

export interface MoveChartModalProps {
  chartName: string;
  dashboards: DashBoard[];
  onClose: () => void;
  onSave: (dashboard: DashBoard) => Promise<void>;
}

export function MoveChartModal({ chartName, onClose, dashboards, onSave }: MoveChartModalProps) {
  const [selectedDashboard, setSelectedDashboard] = React.useState<DashBoard | undefined>(
    dashboards[0],
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const { showSnackbarMessage } = useSnackbar();

  async function save() {
    try {
      setIsSaving(true);
      await onSave(selectedDashboard!);
      onClose();
      showSnackbarMessage({
        message: `${chartName} moved to ${selectedDashboard?.name}`,
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      showSnackbarMessage({ message: 'Failed to move chart', type: 'error' });
      setIsSaving(false);
    }
  }

  return (
    <FullscreenModal title="Move" onClose={onClose}>
      <div data-testid="move-chart-modal">
        {dashboards.map(dashboard => (
          <RadioButton
            key={dashboard.id}
            title={dashboard.name}
            isChecked={dashboard.id === selectedDashboard?.id}
            onChange={() => setSelectedDashboard(dashboard)}
          />
        ))}
      </div>
      <div className="u-display--flex u-justify-content--flex-end u-margin-top--large">
        <button
          data-testid="close-move-chart-modal"
          className="e-button e-button--small e-button--tertiary"
          onClick={onClose}
        >
          Cancel
        </button>
        <Button
          message={isSaving ? 'Saving...' : 'Save'}
          onClick={save}
          className="u-margin-left--small"
        />
      </div>
    </FullscreenModal>
  );
}
