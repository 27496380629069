import { FC } from 'react';
import styles from './filter-bar.module.css';
import { GlobalCustomFilters } from '../../../components/toolbar/custom-filters';
import { GlobalBiddingTypeDropdown } from '../../../views/analytics/global-bidding-type-dropdown/global-bidding-type-dropdown';
import { GlobalActiveFilters } from '../../../components/toolbar/active-filters';
import { useFilters } from '../../context/filter-context';
import { DASHBOARD_ROUTE, FEATURE_FLAGS } from '../../../constants';
import { BiddingTypeSelector } from '../../../components/bidding-type-selector/bidding-type-selector';
import { useLocation } from 'react-router-dom';
import { useCurrentFilterStorageKey } from '../../../hooks/use-current-filter-storage-key/use-current-filter-storage-key';

export const FilterBar: FC = () => {
  const { hideBiddingTypeDropdown } = useFilters();
  const { pathname } = useLocation();
  const filterStorageKey = useCurrentFilterStorageKey();

  const isOnDashboard = pathname === DASHBOARD_ROUTE;

  function biddingTypeDropdown() {
    if (hideBiddingTypeDropdown) return;
    if (isOnDashboard) return <BiddingTypeSelector />;
    return <GlobalBiddingTypeDropdown />;
  }

  const getNewOrOldGFilters = () => {
    if (!FEATURE_FLAGS.shouldUseNewFilterDesign)
      return (
        <div className={styles.filterContainer}>
          <GlobalCustomFilters filterStorageKey={filterStorageKey} />
          {biddingTypeDropdown()}
        </div>
      );

    return (
      <>
        <GlobalCustomFilters filterStorageKey={filterStorageKey} />
        {biddingTypeDropdown()}
      </>
    );
  };

  return (
    <section>
      <article
        className={FEATURE_FLAGS.shouldUseNewFilterDesign ? styles.newFilterBar : styles.filterBar}
      >
        {getNewOrOldGFilters()}
        <GlobalActiveFilters filterStorageKey={filterStorageKey} />
      </article>
    </section>
  );
};
