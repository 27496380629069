import { FC } from 'react';
import { Props } from './selector-area.types';
import './selector-area.css';
import { translate } from '../../../../helpers/utils';

export const SelectorArea: FC<Props> = ({ qaId, onCancel, onSave, children }) => {
  return (
    <section className="u-background--whitesmoke u-border-radius">
      <div className="u-display--flex" data-qa={qaId} data-testid={qaId}>
        {children}
      </div>
      <div
        className={`
          u-display--flex
          u-align-items--center
          u-border--juri-very-light
          u-border-vertical
          u-padding--medium
        `}
      >
        <button
          className="e-button e-button--small e-button--primary u-margin-right--small"
          type="button"
          onClick={onSave}
          data-qa="selector-bar-apply"
        >
          {translate('analytics_home_apply')}
        </button>
        <button
          className="e-button e-button--small e-button--link u-color--blue u-font-weight--normal"
          onClick={onCancel}
          data-qa="selector-bar-cancel"
        >
          {translate('analytics_home_cancel')}
        </button>
      </div>
    </section>
  );
};
