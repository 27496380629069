import * as React from 'react';
import { Props } from './dashboard-header-name.types';
import styles from './dashboard-header-name.module.css';
import { translate, pressKeyEnter } from '../../../../helpers/utils';
import { DashboardDropdown } from '../dashboard-dropdown';
import cn from 'classnames';
import { WrapperErrorHint } from '../../../../components/wrapper-error-hint';
import { useNameValidation } from '../../hooks/use-name-validation';
import { FC, useState, useEffect } from 'react';
import {
  dashboardValidationProps,
  DASHBOARD_ERROR_VALIDATION_TO_TRANSLATION_KEY,
} from '../../../../constants';

export const DashboardHeaderName: FC<Props> = ({
  selectedDashboard,
  dashboards,
  onCreateDashboard,
  onSelectDashboard,
  onChangeName,
}) => {
  const [isNameBeingEdited, setIsNameBeingEdited] = useState(false);
  const { name, setName, isValid, validationErrors, shouldShowErrors, setShouldShowErrors } =
    useNameValidation(dashboardValidationProps(dashboards), selectedDashboard.name);

  useEffect(() => {
    setName(selectedDashboard.name);
    setShouldShowErrors(false);
  }, [isNameBeingEdited, setName, selectedDashboard.name, setShouldShowErrors]);

  const changeName = (nameValue: string) => {
    setName(nameValue);
    setShouldShowErrors(false);
  };

  const clickSaveButton = () => {
    if (isValid) {
      onChangeName(name);
      setIsNameBeingEdited(false);
    } else {
      setShouldShowErrors(true);
    }
  };

  if (!isNameBeingEdited)
    return (
      <div className={styles.dashboardHeaderNameWrapper}>
        <div className={styles.dashboardHeaderName}>
          <h1 className={styles.title}>{selectedDashboard.name}</h1>
          <DashboardDropdown
            selectedDashboardId={selectedDashboard.id}
            dashboards={dashboards}
            onCreateDashboard={onCreateDashboard}
            onSelectDashboard={onSelectDashboard}
          />
        </div>
        <span className={styles.editDashboardNameButton} onClick={() => setIsNameBeingEdited(true)}>
          {translate('dashboard_header_name_edit')}
        </span>
      </div>
    );

  return (
    <div className={cn(styles.dashboardHeaderNameWrapper, styles.isEdited)}>
      <div className={styles.dashboardHeaderName}>
        <WrapperErrorHint
          message={translate(DASHBOARD_ERROR_VALIDATION_TO_TRANSLATION_KEY[validationErrors[0]])}
          isShown={!isValid && shouldShowErrors}
        >
          <input
            className={cn(styles.inputField, 'c-input', {
              [styles.inputError]: !isValid && shouldShowErrors,
            })}
            autoFocus
            onChange={event => changeName(event.target.value)}
            onKeyPress={event => pressKeyEnter(event.key, clickSaveButton)}
            value={name}
          />
        </WrapperErrorHint>
        <button
          className={cn('e-button e-button--small e-button--primary', styles.saveButton)}
          type="button"
          onClick={clickSaveButton}
        >
          {translate('common_save')}
        </button>
        <button
          className="e-button e-button--small e-button--tertiary"
          type="button"
          onClick={() => {
            setIsNameBeingEdited(false);
            setName(selectedDashboard.name);
          }}
        >
          {translate('common_cancel')}
        </button>
      </div>
    </div>
  );
};
