import { FC } from 'react';
import { translate } from '../../../helpers/utils';
import { useFilters } from '../../../global/context/filter-context';
import {
  DropdownDiscriminatorType,
  DropdownOption,
  MultiSelectDropdown,
} from '../../../components/multi-select-dropdown';
import { getAllCampaignTypes } from '../../../models';
import { MenuDirection } from '../../../components/menu';
import trackerService from '../../../services/tracker/tracker-service';
import { useCurrentView } from '../../../hooks/use-current-view/use-current-view';
import { FEATURE_FLAGS, VIEW_NAME } from '../../../constants';
import styles from './global-bidding-type-dropdown.module.css';
import cn from 'classnames';
import tagStyles from '../../../core-ui/components/tag/tag.module.css';

export const GlobalBiddingTypeDropdown: FC = () => {
  const { selectedBiddingTypes, setSelectedBiddingTypes } = useFilters();
  const currentView = useCurrentView();
  const clickApply = (options: DropdownOption[]) => {
    trackerService.track('Change Bidding types', {
      biddingTypes: options.map(({ value }) => value),
      view: currentView ? VIEW_NAME[currentView] ?? currentView : 'Unknown',
      url: window.location.href,
    });
    setSelectedBiddingTypes(options.map(({ value }) => value));
  };

  const classNames = FEATURE_FLAGS.shouldUseNewFilterDesign
    ? [tagStyles.tag, tagStyles.selected, styles.biddingTypeDropdownTag]
    : styles.biddingTypeDropdownField;

  return (
    <MultiSelectDropdown
      key="key-campaign-types-filter"
      options={getAllCampaignTypes().map(({ code }) => ({ key: code, value: code }))}
      selectedByDefaultOptions={selectedBiddingTypes.map(code => ({ key: code, value: code }))}
      menuDirection={MenuDirection.BottomLeading}
      texts={{
        discriminatorColumn: DropdownDiscriminatorType.DYNAMIC,
        singleSelected: translate('analytics_bidding_type_dropdown_single_selected'),
        multipleSelected: translate('analytics_bidding_type_dropdown_multiple_selected'),
        informationTooltip: translate('analytics_bidding_type_dropdown_information_tooltip'),
        noneSelectedError: translate('analytics_bidding_types_dropdown_selection_error'),
      }}
      onApply={clickApply}
      className={cn(classNames)}
    />
  );
};
