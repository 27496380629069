import { FC, useContext, useEffect, useState } from 'react';
import {
  useDateRangePeriod,
  useIsComparing,
} from '../../../components/date-picker/date-picker.state';
import { Grid, SortDirection } from '../../../components/grid';
import { getComparedRows } from '../../../components/grid/utils/grid-utils';
import { PeriodPicker } from '../../../components/period-picker';
import { ResultsNotFound } from '../../../components/results-not-found';
import { ShownMetricsSelector } from '../../../components/shown-metrics-selector';
import { Toolbar } from '../../../components/toolbar';
import { ViewExporter } from '../../../components/view-exporter';
import {
  COLUMN_KEY_ANOMALIES,
  COLUMN_KEY_DATE,
  GRID_VIEW_CONFIGURATION,
  NotificationLevel,
  TimeOption,
  VIEW_NAME,
} from '../../../constants';
import { AppContext } from '../../../global/context/app-context';
import { formatColumnsAndRows } from '../../../helpers/grid-formatter';
import { translate } from '../../../helpers/utils';
import { useShownMetricsSelector } from '../../../hooks/use-shown-metrics-selector';
import { Aggregation, FileFormat, View, fromTo } from '../../../models';
import { getPartnersId } from '../../../services/app-service';
import { showNotification } from '../../../services/notification-service';
import ReportService from '../../../services/report-service';
import styles from '../common-view-styles.module.css';
import { AbsoluteRelativeToggle } from '../hotel-view/absolute-relative-toggle';
import { useAbsoluteOrRelativeComparison } from '../use-absolute-or-relative-comparison';
import { withSortTracking } from '../with-sort-tracking';
import { AGGREGATION_TIME_OPTION, TimeViewProps } from './time-view.types';
import { useTimeView } from './use-time-view';

export const TimeView: FC<TimeViewProps> = () => {
  const [isComparing] = useIsComparing();
  const { partner, subPartners, selectedLocales } = useContext(AppContext);
  const [timeOptionSelected, setTimeOptionSelected] = useState(
    isComparing ? ('Custom' as TimeOption) : TimeOption.DAY,
  );
  const [{ from, to }] = useDateRangePeriod();
  const { activeTrend } = useAbsoluteOrRelativeComparison();

  const view = View.TIME;
  const aggregation = isComparing
    ? Aggregation.TOTAL
    : TIME_OPTION_TO_AGGREGATION[timeOptionSelected];
  const columnId = COLUMN_KEY_DATE;
  const numOfStickyColumns = 1;

  const requestPayload = {
    locales: selectedLocales,
    from,
    to,
  };

  useEffect(() => {
    if (isComparing) {
      setTimeOptionSelected('Custom' as TimeOption);
    } else {
      setTimeOptionSelected(TimeOption.DAY);
    }
  }, [isComparing]);

  const {
    table: { columns, data, total },
    isLoading,
    retry,
    pagination,
    filters: { allActiveFilters, hasGlobalFilters, clearGlobalFilters },
    sort: { sort, setSort },
  } = useTimeView(aggregation, requestPayload);

  const exportRasReport = async () => {
    try {
      return await ReportService.export(
        View.RAS_REPORT,
        [AGGREGATION_TIME_OPTION[timeOptionSelected]],
        FileFormat.CSV,
        {
          partnersId: getPartnersId(partner, Array.from(subPartners)),
          locales: selectedLocales,
          from,
          to,
          filters: [],
        },
      );
    } catch (e) {
      showNotification({
        message: (e as any).message,
        level: NotificationLevel.ERROR,
      });
    }
  };

  let timePeriodData = data.rows;

  if (isComparing && timePeriodData?.length === 2) {
    timePeriodData = getComparedRows(timePeriodData, columns, activeTrend);
  }

  const { formattedColumns, formattedRows, formattedFooterRows } = formatColumnsAndRows({
    columns,
    rows: timePeriodData,
    total,
    id: columnId,
    customColumns: {
      columnCellClickActions: {
        [COLUMN_KEY_ANOMALIES]: { action: exportRasReport },
      },
    },
  });

  const { shownMetrics, setShownMetrics, availableMetrics, visibleFormattedColumns } =
    useShownMetricsSelector(view, numOfStickyColumns, columns, formattedColumns);

  const viewExporter = () => (
    <ViewExporter
      key="key-view-exporter"
      title={translate('analytics_home_export_report')}
      exportOptions={[
        {
          text: translate('analytics_toolbar_export_performance_grid'),
          fileFormat: FileFormat.CSV,
        },
        {
          text: translate('analytics_toolbar_export_performance_grid_excel'),
          fileFormat: FileFormat.EXCEL,
        },
      ]}
      view={view}
      columns={columns}
      aggregation={TIME_OPTION_TO_AGGREGATION[timeOptionSelected]}
      filters={allActiveFilters}
      sort={sort}
    />
  );

  const gridPagination = () => {
    if (isComparing) return;
    return {
      page: pagination.pagination,
      totalItems: data.count,
      onPreviousPage: pagination.setPrevPage,
      onNextPage: pagination.setNextPage,
      onUpdateRowsPerPage: pagination.setNumItemsPerPage,
    };
  };

  const handleGridSort = () => {
    if (isComparing) return;
    return (columnName: string, direction: SortDirection) => {
      setSort({ columnName, direction });
    };
  };

  return (
    <div className={styles.toolbarWrapper}>
      <Toolbar
        filters={[
          <PeriodPicker
            key="key-period-picker"
            onSelectTimeOption={setTimeOptionSelected}
            timeOptionSelected={timeOptionSelected}
            disabled={isComparing}
          />,
        ].concat(isComparing ? [<AbsoluteRelativeToggle viewName="Time Period" />] : [])}
        actions={[
          <ShownMetricsSelector
            key="key-metrics-selector"
            options={availableMetrics}
            selectedByDefaultOptions={shownMetrics}
            view={view}
            onApply={setShownMetrics}
          />,
          viewExporter(),
        ]}
      />
      <div className={styles.gridWrapper}>
        <Grid
          numStickyColumns={numOfStickyColumns}
          columns={visibleFormattedColumns}
          rows={formattedRows}
          footerRows={formattedFooterRows}
          isLoading={isLoading}
          configuration={GRID_VIEW_CONFIGURATION}
          onSort={withSortTracking(handleGridSort(), VIEW_NAME[view])}
          pagination={gridPagination()}
          resultsNotFound={
            <ResultsNotFound
              isButtonEnabled={hasGlobalFilters && !isLoading}
              onClick={clearGlobalFilters}
              retry={retry}
            />
          }
          isAccordionTable={isComparing}
        />
      </div>
    </div>
  );
};

const TIME_OPTION_TO_AGGREGATION: fromTo<TimeOption, Aggregation> = {
  [TimeOption.DAY]: Aggregation.TIME_DAY,
  [TimeOption.WEEK]: Aggregation.TIME_WEEK,
  [TimeOption.MONTH]: Aggregation.TIME_MONTH,
  [TimeOption.QUARTER]: Aggregation.TIME_QUARTER,
};
