import * as React from 'react';
import { Props } from './admin-partner-row.types';
import './admin-partner-row.css';
import DeleteLineDarkIcon from 'tcp-react-icons/lib/DeleteLineDarkIcon';

export const AdminPartnerRow: React.FunctionComponent<Props> = ({
  partner: { partnerId, name },
  onUnAssignPartner,
}) => {
  return (
    <>
      <td className="u-font-weight-bold u-font-size--h-tiny">{partnerId}</td>
      <td className="u-font-size--h-tiny">{name}</td>
      <td>
        <button
          className="e-button e-button--no-style"
          onClick={() => onUnAssignPartner(partnerId)}
        >
          <DeleteLineDarkIcon className="u-color--red u-margin-right--t-small" />
        </button>
      </td>
    </>
  );
};
