import { FC } from 'react';
import { InputField } from '../../../core-ui/components/input/input';
import { isNumericRangeFilter } from '../is-numeric-range-filter';
import { OldFilter } from '../types';
import { FilterFormFieldContext } from './filter-form-field-context';
import styles from './filter-form-field.module.css';
import { QualifierSelector } from './qualifier-selector';
import { useFilterFormField } from './use-filter-form-field';

export type FilterFormFieldProps = {
  filter: OldFilter;
  setFilter: (filter: OldFilter) => void;
};

export const FilterFormField: FC<FilterFormFieldProps> = ({ filter, setFilter }) => {
  return (
    <FilterFormFieldContext.Provider value={{ filter, setFilter }}>
      <section className={styles.filterFormField}>
        <div className={styles.nameAndQualifier}>
          <p className={styles.filterName}>{filter.name}</p>
          <QualifierSelector />
        </div>
        <div className={styles.inputs}>
          <FilterFormFieldInputs />
        </div>
      </section>
    </FilterFormFieldContext.Provider>
  );
};

const FilterFormFieldInputs: FC = () => {
  const { filter, setValue } = useFilterFormField();

  if (isNumericRangeFilter(filter)) {
    return (
      <>
        <InputField
          key="from"
          value={filter.value?.from as number}
          type="numeric"
          onInputChange={e => setValue({ from: Number(e.target.value), to: filter.value?.to })}
          decoration={filter.decoration}
        />
        <span className={styles.inputSeparator}>-</span>
        <InputField
          key="to"
          value={filter.value?.to as number}
          type="numeric"
          onInputChange={e => setValue({ from: Number(e.target.value), to: filter.value?.to })}
          decoration={filter.decoration}
        />
      </>
    );
  }

  return (
    <InputField
      value={filter.value}
      onInputChange={e => setValue(e.target.value)}
      type={filter.type}
      decoration={filter.decoration}
    />
  );
};
