import React, { FC } from 'react';
import { FetchError } from '../../components/error/fetch-error';
import { Loading } from '../../components/loading';
import { translate } from '../../helpers/utils';
import { useAllReports } from '../../hooks/use-all-reports';
import { ReportFrequency } from '../../models';
import { Report } from './report.types';
import { ReportRow } from './report-row/report-row';
import { getDisplayName } from './util';

interface InactiveReportsProps {
  onShowReportDetails: (report: Report) => void;
  isSftpFolderSetup: boolean;
  onSetupSftpFolder: () => void;
  onSubscribeClick: (report: Report) => void;
}

export const InactiveReports: FC<InactiveReportsProps> = props => {
  const { onShowReportDetails, onSetupSftpFolder, isSftpFolderSetup, onSubscribeClick } = props;
  const { reports, isLoading, error, refetch } = useAllReports();

  return (
    <div className="c-reports-type-list__container">
      <div className="c-reports-type-list__title u-color--juri-lighter u-margin-bottom--medium">
        {translate('reports_inactive_reports')}
      </div>
      {(() => {
        if (isLoading) return <Loading />;
        if (error) return <FetchError message={error} retry={refetch} />;

        return (
          <table className="c-reports-type-list" style={{ position: 'relative', minHeight: 150 }}>
            {!isSftpFolderSetup && <SftpFolderNotSetUpLayover onButtonClick={onSetupSftpFolder} />}
            <tbody>
              {reports.map(report => (
                <ReportRow
                  key={report.name}
                  displayName={getDisplayName(report)}
                  isActive={false}
                  frequency={ReportFrequency.DAILY}
                  onClick={() => onShowReportDetails(report)}
                  onEnableDisableReport={() => onSubscribeClick(report)}
                />
              ))}
            </tbody>
          </table>
        );
      })()}
    </div>
  );
};

const SftpFolderNotSetUpLayover: FC<{ onButtonClick: () => void }> = ({ onButtonClick }) => {
  return (
    <div
      style={{
        position: 'absolute',
        background: 'rgba(255,255,255,0.75)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <h3 style={{ marginBottom: 10 }}>SFTP folder is not set up</h3>
      <button onClick={onButtonClick} className="e-button e-button--small e-button--primary">
        Set it up
      </button>
    </div>
  );
};
