import React from 'react';
import { translate } from '../../helpers/utils';

type Props = {
  message: string | React.ReactNode;
  onClick: () => void;
};

export default function ButtonRetry({ message, onClick }: Props) {
  return (
    <div className="u-display--flex u-flex-direction--column u-margin-bottom--huge u-align-items--center u-height--100 u-justify-content--center">
      <div className="u-color--juri-lighter position-center center">{message}</div>
      <button
        onClick={onClick}
        className="e-button e-button--small e-button--tertiary u-display--flex u-align-items--center u-margin-top--small"
      >
        {translate('common_retry')}
      </button>
    </div>
  );
}
