import cn from 'classnames';
import { ComponentProps, FC } from 'react';
import { Icon, IconProps } from '../icon';
import styles from './menu.module.css';

export type MenuItem = {
  id: number | string;
  label: string;
  onClick?: VoidFunction;
  icon?: IconProps;
  disabled?: boolean;
};

export type MenuProps = {
  items: MenuItem[];
} & ComponentProps<'div'>;

export const Menu: FC<MenuProps> = ({ items, className, ...props }) => (
  <div {...props} className={cn(styles.menu, className)}>
    <ul>
      {items.map(item => (
        <MenuListItem key={item.id} item={item} />
      ))}
    </ul>
  </div>
);

const MenuListItem: FC<{ item: MenuItem }> = ({ item }) => (
  <li
    className={cn(styles.menuItem, {
      [styles.disabled]: item.disabled,
    })}
    title={item.label}
    onClick={item.disabled ? undefined : item.onClick}
  >
    {item.icon ? <Icon {...item.icon} className={styles.icon} /> : null}
    <p className={styles.label}>{item.label}</p>
  </li>
);
