import React from 'react';
import { translate } from '../../helpers/utils';
import { TeapotAnimation } from '../teapot-animation';

export const FetchError = (props: { message: string; retry: VoidFunction }) => {
  return (
    <div className="u-display--flex u-flex-direction--column u-align-items--center u-justify-content--center">
      <TeapotAnimation message={props.message} />
      <button
        onClick={() => props.retry()}
        className="e-button e-button--small e-button--tertiary u-display--flex u-align-items--center u-margin-top--small"
      >
        {translate('common_retry')}
      </button>
    </div>
  );
};
