import React, { SyntheticEvent } from 'react';
import { CloseIcon, SearchLineDarkIcon } from 'tcp-react-icons';
import { InputIcon } from '../input-icon';

type HeaderProps = {
  title?: string | React.ReactNode;
  onSelectAll?: VoidFunction;
  onClearSelection?: VoidFunction;
  onSearchTextChange?: (value: string) => void;
  searchPlaceholder?: string;
};

export const CheckboxSelectHeader: React.FunctionComponent<HeaderProps> = ({
  title,
  onSelectAll,
  onClearSelection,
  onSearchTextChange,
  searchPlaceholder,
}) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const [showClearSearchButton, setShowClearSearchButton] = React.useState(false);

  function onInput(e: SyntheticEvent) {
    if (!onSearchTextChange) return;
    const value = (e.target as HTMLInputElement).value;
    setShowClearSearchButton(!!value);
    onSearchTextChange(value);
  }

  function onClearInputIconClick() {
    if (showClearSearchButton && onSearchTextChange && inputRef.current) {
      inputRef.current.value = '';
      onSearchTextChange('');
      setShowClearSearchButton(false);
    }
  }

  function renderTitle() {
    if (!title) return null;
    if (typeof title === 'string') return <h4 className="title">{title}</h4>;
    return title;
  }

  return (
    <div className="checkbox-select-header">
      {renderTitle()}
      {onSearchTextChange && (
        <div className="search-box">
          <InputIcon
            className="c-input u-width--100 u-border-radius"
            placeholder={searchPlaceholder}
            icon={showClearSearchButton ? CloseIcon : SearchLineDarkIcon}
            onChange={onInput}
            onClickIcon={onClearInputIconClick}
            ref={inputRef}
            onFocus={() => inputRef.current?.focus()}
          />
        </div>
      )}
      {(onSelectAll || onClearSelection) && (
        <div className="u-display--flex u-justify-content--space-between">
          {onSelectAll && <button onClick={onSelectAll}>Select all</button>}
          <button onClick={onClearSelection} className="u-margin-left--auto">
            Clear selection
          </button>
        </div>
      )}
    </div>
  );
};
