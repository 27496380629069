import * as React from 'react';
import { createRef } from 'react';
import { Props } from './widget-menu.types';
import './widget-menu.css';
import { Menu } from '../../../../components/menu';
import { useOnClickOutside } from '../../../../hooks/use-on-click-outside';
import icon from './kebab-menu-icon.svg';
import cn from 'classnames';

export const WidgetMenu: React.FunctionComponent<Props> = ({
  menuItems,
  onSelectMenuItem,
  isDarker,
  className = '',
}) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const ref = createRef<HTMLDivElement>();
  useOnClickOutside(ref, () => setIsOpened(false));

  const clickMenuItem = (index: number) => {
    onSelectMenuItem(index);
    setIsOpened(false);
  };

  return (
    <div ref={ref} className={className}>
      <img
        src={icon}
        className={cn('u-cursor--pointer c-widget-menu__img', {
          'c-widget-menu__opened': isOpened,
          'c-widget-menu__darker': isDarker,
        })}
        onClick={() => setIsOpened(!isOpened)}
        data-testid="widget-menu-toggle-icon"
        alt="Verical ellipsis icon for more options for dashboard"
      />
      <div className="c-widget-menu u-position--relative">
        {isOpened && (
          <Menu body={{ items: menuItems }} onSelectItem={index => clickMenuItem(index)} />
        )}
      </div>
    </div>
  );
};
