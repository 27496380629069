import { FC } from 'react';
import { GridSortButtonProps } from './grid-sort-button.types';
import styles from './grid-sort-button.module.css';
import DirectionIcon from 'tcp-react-icons/lib/DirectionIcon';
import cn from 'classnames';
import { SortDirection } from '../../models/grid-model';

export const GridSortButton: FC<GridSortButtonProps> = ({ onSort, column, sort, extraClasses }) => {
  const direction = sort?.direction ?? SortDirection.DESC;
  return (
    <button
      className={cn('e-button--no-style', styles.sortButton, extraClasses, {
        [styles.active]: column.key === sort?.columnKey,
      })}
      onClick={onSort}
    >
      <DirectionIcon
        className={
          direction === SortDirection.DESC ? 'e-icon--direction-down' : 'e-icon--direction-up'
        }
      />
    </button>
  );
};
