import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';
import styles from './button-dropdown.module.css';
import { Props, ButtonDropdownOption } from './button-dropdown.types';
import { EVENT_MOUSEDOWN } from '../../constants';

// TODO: remove class name and replace it with variant (button variant)
export const ButtonDropdown = <T,>({
  title,
  optionNodes,
  options,
  onSelectOption,
  classNameButton,
  classNameOpenButton,
  qaId,
  className,
  children,
  footerHint,
  disabled,
  position = 'right',
}: Props<T>) => {
  const [isActive, setIsActive] = useState(false);
  const buttonDropdownRef: RefObject<HTMLDivElement> = useRef(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as Node;
    if (buttonDropdownRef.current && !buttonDropdownRef.current.contains(target)) {
      setIsActive(false);
    }
  }, []);

  const toggleActive = () => {
    setIsActive(currValue => !currValue);
  };

  const selectOption = (option: ButtonDropdownOption, data?: T) => {
    if (option.isDisabled) return;
    if (onSelectOption) {
      onSelectOption(option.value, data);
    }
    setIsActive(false);
  };

  useEffect(() => {
    document.addEventListener(EVENT_MOUSEDOWN, handleClickOutside);
    return () => document.removeEventListener(EVENT_MOUSEDOWN, handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div ref={buttonDropdownRef} className={cn(styles.buttonDropdown, className, styles[position])}>
      <button
        data-qa={qaId}
        data-testid={qaId}
        className={cn(
          'e-button e-button--small u-height--100 u-display--flex u-align-items--center',
          classNameButton,
          {
            'e-button--tertiary': !classNameButton,
          },
          isActive && !!classNameOpenButton ? classNameOpenButton : undefined,
        )}
        onClick={toggleActive}
        disabled={disabled}
      >
        <span className="u-margin-right--small">{title}</span>
        <ArrowIcon
          className={cn('u-transition--quick', {
            'u-transform--rotate-90deg': !isActive,
            'u-transform--rotate-minus-90deg': isActive,
            [styles.buttonDropdownDisabled]: disabled,
          })}
        />
      </button>
      {isActive && options && options.length > 0 && (
        <ol className={cn(styles.buttonDropdownPopup)}>
          {options.map((optionTmp, index) =>
            optionNodes !== undefined ? (
              <li
                data-qa={`dropdown-option-${index}`}
                key={index}
                className={cn(styles.buttonDropdownUploadItem, {
                  [styles.buttonDropdownDisabled]: optionTmp.isDisabled,
                })}
              >
                {optionNodes[index]}
              </li>
            ) : (
              <li
                data-qa={`dropdown-option-${index}`}
                key={index}
                className={cn({
                  [styles.buttonDropdownDisabled]: optionTmp.isDisabled,
                })}
                onClick={() => selectOption(optionTmp)}
              >
                {optionTmp.icon && (
                  <img
                    src={optionTmp.icon}
                    className="c-panel-control__icon-dropdown u-margin-right--medium"
                    alt="Icon depicting the dropdown"
                  />
                )}
                {optionTmp.text}
                <span className="u-font-weight-bold">{optionTmp.extraText}</span>
              </li>
            ),
          )}
          {footerHint && (
            <li className="u-cursor-default u-background--white u-color--juri-light u-line-height--small u-font-size--tiny">
              {footerHint}
            </li>
          )}
        </ol>
      )}
      {isActive && !options ? <ol className={styles.buttonDropdownPopup}>{children}</ol> : null}
    </div>
  );
};
