import { Column, ColumnType } from '../../../components/grid';
import { ColumnDef } from '../../../models';
import { PropertyGroupCell } from './property-group-cell';
import styles from './property-group-cell.module.css';

export const PROPERTY_GROUPS_VIEW_COLUMNS: Column[] = [
  {
    key: 'group',
    name: 'Property Group',
    type: ColumnType.CUSTOM,
    children(data, row, nestingLevel) {
      if (nestingLevel && nestingLevel > 1) return data;
      return <PropertyGroupCell row={row} />;
    },
    cellClassName: styles.tableCell,
  },
  {
    key: 'clicks2',
    name: 'Clicks',
    type: ColumnType.INT,
  },
  {
    key: 'booking_conversion2',
    name: 'Booking Rate',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'bookings2',
    name: 'Bookings',
    type: ColumnType.INT,
  },
  {
    key: 'cost2',
    name: 'Cost',
    type: ColumnType.CURRENCY,
  },
  {
    key: 'hotel_impressions2',
    name: 'Hotel Impr',
    type: ColumnType.INT,
  },
  {
    key: 'top_pos_share2',
    name: 'Top Pos Share',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'impressions_share2',
    name: 'Impr Share',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'beat_share2',
    name: 'Beat',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'meet_share2',
    name: 'Meet',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'lose_share2',
    name: 'Lose',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'unavailable',
    name: 'Unavailability',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'outbid_rate2',
    name: 'Outbid Ratio',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'bm36',
    name: 'Beat vs Champ',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'bm37',
    name: 'Meet vs Champ',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'bm38',
    name: 'Lose vs Champ',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'cpa_modified',
    name: 'CPA',
    type: ColumnType.PERCENTAGE,
  },
  {
    key: 'gross_revenue2',
    name: 'Gross Revenue',
    type: ColumnType.CURRENCY,
  },
  {
    key: 'average_booking_volume2',
    name: 'ABV',
    type: ColumnType.CURRENCY,
  },
  {
    key: 'base_bid_cost',
    name: 'Base Bid Cost',
    type: ColumnType.CURRENCY,
  },
];

const PROPERTY_GROUPS_VIEW_FILTER_ONLY_COLUMNS: Column[] = [
  {
    key: 'accommodation_type',
    name: 'Accommodation Type',
    type: ColumnType.TEXT,
  },
  {
    key: 'city',
    name: 'City',
    type: ColumnType.TEXT,
  },
  {
    key: 'country',
    name: 'Country',
    type: ColumnType.TEXT,
  },
  {
    key: 'region',
    name: 'Region',
    type: ColumnType.TEXT,
  },
  {
    key: 'partner_reference',
    name: 'PartnerRef',
    type: ColumnType.TEXT,
  },
  {
    key: 'hotel_name',
    name: 'Hotel Name',
    type: ColumnType.TEXT,
  },
  {
    key: 'stars',
    name: 'Stars',
    type: ColumnType.INT,
  },
];

const PROPERTY_GROUPS_VIEW_FILTER_ONLY_COLUMN_DEFINITIONS: ColumnDef[] =
  PROPERTY_GROUPS_VIEW_FILTER_ONLY_COLUMNS.map(c => ({
    key: c.key,
    name: (c as any).name,
    type: c.type === ColumnType.TEXT ? 'string' : 'numeric',
    isComparable: true,
    isHidden: true,
    isHiddenTotal: true,
    tooltip: '',
    wrapper: '',
  }));

// Skip the first column (group) for filtering because it's ambiguous (used as accordion)
// it can represent a property group, bid modifier or bid modifier breakout
export const PROPERTY_GROUPS_VIEW_COLUMN_DEFINITIONS: ColumnDef[] =
  PROPERTY_GROUPS_VIEW_COLUMNS.slice(1)
    .map(c => ({
      key: c.key,
      name: (c as any).name,
      type: c.type === ColumnType.TEXT ? 'string' : 'numeric',
      isComparable: true,
      isHidden: false,
      isHiddenTotal: false,
      tooltip: '',
      wrapper: '',
    }))
    .concat(...PROPERTY_GROUPS_VIEW_FILTER_ONLY_COLUMN_DEFINITIONS);
