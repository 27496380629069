import * as React from 'react';
import { createRef, useState } from 'react';
import PlusThinDarkIcon from 'tcp-react-icons/lib/PlusThinDarkIcon';
import { Button } from '../../../components/button';
import { Menu } from '../../../components/menu';
import { CHART_BUILDER_CREATE_START, FEATURE_FLAGS } from '../../../constants';
import { translate } from '../../../helpers/utils';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';
import { WidgetType } from '../../../models';
import TrackerService from '../../../services/tracker/tracker-service';
import './add-widget-dropdown.css';
import { Props } from './add-widget-dropdown.types';
import { AddWidgetItemMenu } from './add-widget-item-menu';

export const AddWidgetDropdown: React.FunctionComponent<Props> = ({ onSelectWidget }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = createRef<HTMLDivElement>();
  useOnClickOutside(ref, () => setIsOpen(false));
  const menuWidgets = [
    WidgetType.CHART_BUILDER,
    WidgetType.TIMELINE,
    WidgetType.YESTERDAY_OVERVIEW,
    WidgetType.POS_OVERVIEW,
    WidgetType.MBLU,
  ];

  if (FEATURE_FLAGS.enableGlobalInventoryWidget) {
    menuWidgets.splice(3, 0, WidgetType.GLOBAL_INVENTORY);
  }

  const onSelect = (index: number) => {
    onSelectWidget(menuWidgets[index]);
    setIsOpen(false);
    if (menuWidgets[index] === WidgetType.CHART_BUILDER)
      TrackerService.track(CHART_BUILDER_CREATE_START);
  };

  const generateMenuItems = () =>
    menuWidgets.map((item, index) => <AddWidgetItemMenu key={index} type={item} />);

  return (
    <div ref={ref}>
      <Button
        message={
          <div className="u-display--flex u-align-items--center u-justify-content--center">
            <PlusThinDarkIcon className="u-margin-right--gutter--small u-color--white" />
            {translate('add_dashboard_dropdown__title')}
          </div>
        }
        onClick={() => setIsOpen(!isOpen)}
      />

      <div className="c-add-widget-dropdown u-position--relative">
        {isOpen && <Menu body={{ items: generateMenuItems() }} onSelectItem={onSelect} />}
      </div>
    </div>
  );
};
