import * as React from 'react';
import { Props } from './widget-row.types';
import './widget-row.css';
import { TooltipWrapper, TooltipPosition } from '../../../../components/tooltip-wrapper';
import ExclamationCircleFillDarkIcon from 'tcp-react-icons/lib/ExclamationCircleFillDarkIcon';
import { TranslationKey, translate } from '../../../../helpers/utils';

export const WidgetRow: React.FunctionComponent<Props> = ({
  field: { text, tooltip },
  children,
}) => {
  const isTooltipAvailable = tooltip !== undefined && tooltip !== '';

  return (
    <tr className="c-widget-row">
      <td>
        {text}
        {isTooltipAvailable && (
          <TooltipWrapper
            message={translate(tooltip as TranslationKey)}
            position={TooltipPosition.TRAILING_TOP}
            noTinyPaddingTop={true}
            isAddingPadding={true}
          >
            <ExclamationCircleFillDarkIcon
              className="u-color--juri-very-light u-margin-left--small"
              width={14}
              height={14}
            />
          </TooltipWrapper>
        )}
      </td>
      {children}
    </tr>
  );
};
