import { FC } from 'react';
import { DropdownDiscriminatorType } from '../multi-select-dropdown';
import { translate } from '../../helpers/utils';
import {
  ANALYTICS_HOTEL_VIEW_SHOWN_METRICS_DROPDOWN,
  SHOWN_METRICS_SELECTOR_PREFIX,
  VIEW_NAME,
} from '../../constants';
import { MultiSelectDropdown } from '../multi-select-dropdown';
import { Props } from './shown-metrics-selector.types';
import { OnboardingTooltip } from '../onboarding-tooltip';
import { PopoverDirection } from '../popover';

export const ShownMetricsSelector: FC<Props> = ({
  view,
  showSearchBox = true,
  isDisabled = false,
  ...props
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', bottom: 5, right: 0 }}>
        <OnboardingTooltip
          keyStorage={ANALYTICS_HOTEL_VIEW_SHOWN_METRICS_DROPDOWN}
          direction={PopoverDirection.BOTTOM_TRAILING}
        >
          <p>{translate('shown_metrics_onboarding_tooltip')}</p>
        </OnboardingTooltip>
      </div>
      <MultiSelectDropdown
        showSearchBox={showSearchBox}
        isDisabled={isDisabled}
        texts={{
          discriminatorColumn: DropdownDiscriminatorType.STATIC,
          disabledTooltip: translate('shown_metrics_dropdown_disabled_tooltip'),
          title: translate('shown_metrics_selector_label'),
          noneSelectedError: translate('shown_metrics_dropdown_selection_error'),
        }}
        tracking={{ prefix: SHOWN_METRICS_SELECTOR_PREFIX(VIEW_NAME[view]) }}
        {...props}
      />
    </div>
  );
};
