import {
  DashboardWidget,
  getAllCampaignTypes,
  Hotel,
  Notification,
  Partner,
  Row,
  STRING_TO_NOTIFICATION_LEVEL,
} from '../models';
import { Layout } from 'react-grid-layout';
import { ApiNotification } from './api/notifications/api-notifications-helper';
import { BiddingTypeOption } from '../components/bidding-type-dimension';

/**
 * analytics transformers
 */

export const row2Hotel = (row: Row): Hotel => {
  const name = row.property_name2 === undefined ? row.name2 : row.property_name2;

  return {
    trvId: row.item_id,
    partnerRef: row.pid,
    name: name,
    trvLink: row.property_url,
    bidCPC:
      row.hv_bid_cpc !== ''
        ? typeof row.hv_bid_cpc === 'string'
          ? +row.hv_bid_cpc
          : row.hv_bid_cpc
        : undefined,
    status: row.status !== '' ? row.status : undefined,
    country: row.property_country,
    region: row.property_region,
    city: row.property_city,
    stars: row.property_stars,
    rating: row.property_overall_liking2,
    lastPushed: row.last_pushed,
    biddingType: row.hv_bidding_type,
    cpaValue: row.hv_cpa,
    slBidCpc: row.hv_sl_bid_cpc === '' ? null : +row.hv_sl_bid_cpc,
  };
};

export const slRow2Hotel = (row: Row): Hotel => {
  return {
    trvId: row.sl_hv_item_id,
    partnerRef: row.sl_hv_partner_ref,
    name: row.sl_hv_name,
    trvLink: row.sl_hv_url,
    bidCPC:
      row.sl_hv_bid_cpc !== ''
        ? typeof row.sl_hv_bid_cpc === 'string'
          ? +row.sl_hv_bid_cpc
          : row.sl_hv_bid_cpc
        : undefined,
    status: row.sl_hv_status !== '' ? row.sl_hv_status : undefined,
    country: row.sl_hv_country,
    region: row.sl_hv_region,
    city: row.sl_hv_city,
    stars: row.sl_hv_stars,
    rating: row.sl_hv_rating,
    lastPushed: '',
    biddingType: '',
    cpaValue: '',
    slBidCpc: null,
  };
};

/**
 * dashboard transformers
 */

export const widgets2layouts = (
  widgets: DashboardWidget[],
  customWidth?: number | null,
): Layout[] => {
  return widgets.map(
    ({
      position: { x, y },
      size: { width, height, maxHeight },
      isDraggable,
      isResizable,
      uuid,
    }) => {
      return {
        i: uuid,
        x,
        y,
        w: customWidth || width,
        h: height,
        maxH: maxHeight,
        isDraggable,
        isResizable,
      };
    },
  );
};

export const layouts2widgets = (
  layouts: Layout[],
  widgets: DashboardWidget[],
): DashboardWidget[] => {
  return layouts.map(layout => {
    const { i, x, y, w, h, maxH, isResizable, isDraggable } = layout;
    const widget = widgets.find(wid => wid.uuid === i)!;

    return {
      uuid: i,
      position: { x, y },
      size: {
        height: h,
        width: w,
        maxHeight: maxH === undefined ? h : maxH,
      },
      isDraggable: isDraggable === undefined ? true : isDraggable,
      isResizable: isResizable === undefined ? true : isResizable,
      type: widget.type,
      props: widget.props,
    };
  });
};

export const data2DrawerNotification = (data: ApiNotification, partner: Partner): Notification => {
  return {
    title: data.data.TITLE || data.data.FILE_NAME || 'New Notification',
    message: data.data.TRANSLATION_KEY,
    link: data.data.LINK,
    level: STRING_TO_NOTIFICATION_LEVEL[data.level],
    brandName: partner.name,
    date: new Date(data.createdAt),
    isRead: data.isRead,
    id: data.id,
  };
};

/**
 * Widget transformers
 */

export const getAllBiddingTypesWithSL = (): BiddingTypeOption[] => {
  return getAllCampaignTypes()
    .map(({ code, value }) => ({ code, value: String(value) }))
    .concat({
      code: 'Sponsored Listing (SL)',
      value: 'SL',
    });
};
