import React, { FC } from 'react';
import { ResultsNotFoundProps } from './results-not-found.types';
import styles from './results-not-found.module.css';
import { TeapotAnimation } from '../teapot-animation';
import { translate, translateHtml } from '../../helpers/utils';
import { TertiaryButton } from '../tertiary-button';
import cn from 'classnames';

export const ResultsNotFound: FC<ResultsNotFoundProps> = props => {
  const { onClick, isButtonEnabled = false, classNames, message, retry = null } = props;
  return (
    <div className={cn(styles.noResultsContainer, classNames)}>
      <TeapotAnimation message={translateHtml(message ?? 'common_no_data_result_message')} />
      {isButtonEnabled && (
        <TertiaryButton onClick={onClick} className={styles.centeredButton}>
          {translate('common_clear_filters_message')}
        </TertiaryButton>
      )}
      {retry && (
        <TertiaryButton onClick={retry} className={styles.retryButton}>
          {translate('common_retry')}
        </TertiaryButton>
      )}
    </div>
  );
};
