import React, { FC, useState, useEffect, useContext, useCallback, createRef } from 'react';
import {
  ActionButtonsProps,
  FiltersDisplayed,
  FilterSetSelectionProps,
  FiltersSelectionProps,
} from './global-custom-filters.types';
import './global-custom-filters.css';
import { Popover, PopoverDirection } from '../../popover';
import FilterLineDarkIcon from 'tcp-react-icons/lib/FilterLineDarkIcon';
import { translate, translateHtml } from '../../../helpers/utils';
import { InputFilter } from './input-filter';
import { InputFilterFromOtherDimension } from './input-filter/input-filter-from-other-dimension';
import { ColumnDef, FilterColumn } from '../../../models';
import iconCreate from './icn-create.svg';
import { useOnClickOutside } from '../../../hooks/use-on-click-outside';
import { useSelectFilterSet } from './hooks/use-select-filter-set';
import { useFilterSetDeleteModal } from './hooks/use-filter-set-delete-modal';
import {
  getActiveFilterSetFromLocalStorage,
  getDimensionFiltersFromLocalStorage,
  saveDimensionFiltersToLocalStorage,
} from '../../../helpers/local-storage-helper';
import {
  getColumnKeys,
  getFiltersMatchingColumns,
  getFiltersNotMatchingColumns,
} from '../../../services/analytics-service';
import { FilterSetOption } from './filter-set-option';
import { useEditFilterSetName } from './hooks/use-edit-filter-set-name';
import { getDefaultFilterSetName } from './helpers/default-filter-set-name';
import {
  areAllFiltersFilled,
  getFilledFilters,
  getGroupedFilters,
  getValidFilters,
  hasInvalidFilters,
  isFilterValid,
} from './helpers/validate-filters';
import { createEmptyFilter } from './helpers/create-filters';
import { ButtonConfirm } from '../../button-confirm';
import ApiAnalyticsHelper from '../../../helpers/api/analytics/api-analytics-helper';
import { AppContext } from '../../../global/context/app-context';
import { getPartner } from '../../../services/app-service';
import TrackerService from '../../../services/tracker/tracker-service';
import {
  FILTERS_ADD_FILTER,
  FILTERS_APPLY_FILTER_SET,
  FILTERS_DELETE_FILTER,
  FILTERS_MODAL_OPEN,
  FILTERS_RENAME,
  FILTERS_SAVE,
  FILTERS_SAVE_AS,
  FilterStorageKey,
} from '../../../constants';
import { useFilters } from '../../../global/context/filter-context';
import cn from 'classnames';
import { VerticalDivider } from '../../vertical-divider';

export const GlobalCustomFilters: FC<{ filterStorageKey: FilterStorageKey }> = ({
  filterStorageKey,
}) => {
  const {
    columns,
    filterTypes,
    filters,
    selectedFilter,
    storedFilterSets,
    closeCustomFiltersModal,
    setSelectedFilter,
    setFilters,
    setStoredFilterSets,
  } = useFilters();
  // Modals
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [isFilterSetDeleteModalOpen] = useFilterSetDeleteModal();
  // Filters data
  const [comparableColumns, setComparableColumns] = useState<ColumnDef[]>([]);
  useEffect(() => {
    setComparableColumns(columns.filter(c => c.isComparable));
  }, [columns]);
  const [filtersCreated, setFiltersCreated] = useState<FilterColumn[]>(
    getValidFilters(filters, comparableColumns),
  );
  const [filtersInOtherDimensions, setFiltersInOtherDimensions] = useState<FilterColumn[]>([]);
  // Filter set - Save
  const [isSavingNewFilterSet, setIsSavingNewFilterSet] = useState(false);
  // Filter set - Name
  const DEFAULT_FILTER_SET_NAME = getDefaultFilterSetName();
  const [newFilterSetFromName, setNewFilterSetFromName] = useState('');
  const [nextFilterSetName, setNextFilterSetName] = useState('');
  const [editingFilterSetName, setEditingFilterSetName] = useEditFilterSetName(nextFilterSetName);
  // Filter set - Selected
  const {
    setSelected: setSelectedFilterSet,
    storeSelected: storeSelectedFilterSet,
    selectedName: selectedSetName,
  } = useSelectFilterSet('', filterStorageKey);
  const isDefaultFilterSetSelected =
    !selectedSetName || selectedSetName === DEFAULT_FILTER_SET_NAME;
  // Partner
  const { partner, subPartners } = useContext(AppContext);
  const { partnerId } = getPartner(partner, subPartners);

  // Filters displayed

  const filtersDisplayedMerged: FilterColumn[] = [...filtersCreated, ...filtersInOtherDimensions];

  const setFiltersDisplayed = useCallback(
    ({
      filtersCreated: _filtersCreated,
      filtersInOtherDimensions: _filtersInOtherDimensions,
    }: FiltersDisplayed) => {
      setFiltersCreated(getValidFilters(_filtersCreated, comparableColumns));
      setFiltersInOtherDimensions(_filtersInOtherDimensions);
    },
    [comparableColumns],
  );

  // Read/write stored filters

  const storeDisplayedToDefaultFilterSet = () =>
    saveDimensionFiltersToLocalStorage(getFilledFilters(filtersDisplayedMerged), filterStorageKey);

  const displayStoredFilters = useCallback(
    (filterSetName: string) => {
      const selectedFilters = isDefaultFilterSetSelected
        ? getDimensionFiltersFromLocalStorage(filterStorageKey)
        : storedFilterSets.find(filterSet => filterSet.name === filterSetName)?.filters || [];
      const groupedFilters = getGroupedFilters(selectedFilters, comparableColumns);
      const columnKeys = getColumnKeys(comparableColumns);
      const filtersMatchingColumns = getFiltersMatchingColumns(groupedFilters, columnKeys);
      const filtersNotMatchingColumns = getFiltersNotMatchingColumns(groupedFilters, columnKeys);
      setFiltersDisplayed({
        filtersCreated: getValidFilters(filtersMatchingColumns, comparableColumns),
        filtersInOtherDimensions: filtersNotMatchingColumns,
      });
    },
    [comparableColumns, isDefaultFilterSetSelected, setFiltersDisplayed, storedFilterSets],
  );

  // CRUD filters

  const createFilter = () => {
    if (hasInvalidFilters(filtersCreated, comparableColumns)) return;
    setFiltersCreated([...filtersCreated, createEmptyFilter()]);
    TrackerService.track(FILTERS_ADD_FILTER);
  };

  const updateFilter = (nextFilter: FilterColumn, index: number) => {
    const nextFiltersCreated = filtersCreated.map((filter, i) =>
      i === index ? nextFilter : filter,
    );
    setFiltersCreated(nextFiltersCreated);
  };

  const removeFilterCreated = (index: number) => {
    const nextFiltersCreated = filtersCreated.filter((_filter, i) => i !== index);
    setFiltersCreated(getValidFilters(nextFiltersCreated, comparableColumns));
    TrackerService.track(FILTERS_DELETE_FILTER);
  };

  const removeFilterInOtherDimensions = (index: number) => {
    const nextFiltersInOtherDimensions = filtersInOtherDimensions.filter(
      (_filter, i) => i !== index,
    );
    setFiltersInOtherDimensions(nextFiltersInOtherDimensions);
  };

  // Save filter set

  const saveFilterSet = async () => {
    if (isDefaultFilterSetSelected) return saveAsNewFilterSet();

    const newFilterSet = {
      name: selectedSetName,
      filters: getFilledFilters(filtersDisplayedMerged),
    };
    const editedFilterSets = storedFilterSets.map(filterSet =>
      filterSet.name === selectedSetName ? newFilterSet : filterSet,
    );

    await ApiAnalyticsHelper.saveFilterSet(partnerId, newFilterSet);
    setStoredFilterSets(editedFilterSets);
    TrackerService.track(FILTERS_SAVE);
  };

  const saveAsNewFilterSet = () => {
    storeDisplayedToDefaultFilterSet();
    //saveFilterSet();
    setNewFilterSetFromName(selectedSetName);
    storeSelectedFilterSet(selectedSetName);
    setIsSavingNewFilterSet(!!selectedSetName);
    TrackerService.track(FILTERS_SAVE_AS);
  };

  // Save filter set name

  const saveFilterSetName = (nextName: string) => {
    // If nextName !== selectedSetName
    setNextFilterSetName(nextName);
    if (nextName) {
      setSelectedFilterSet(nextName);
      setNewFilterSetFromName(nextName);
      setIsSavingNewFilterSet(!!nextName);
      setNextFilterSetName('');
      storeSelectedFilterSet(nextName);
      storeDisplayedToDefaultFilterSet();
      setFilters(getFilledFilters(filtersCreated));
      TrackerService.track(FILTERS_RENAME);
    }
    return;
  };

  const stopEditingFilterSetName = (didSaveName: boolean) => {
    if (!didSaveName) setSelectedFilterSet(newFilterSetFromName);
    setNewFilterSetFromName('');
    setIsSavingNewFilterSet(false);
  };

  // Apply filters and close modal

  const applyFilters = () => {
    storeDisplayedToDefaultFilterSet();
    saveFilterSet();
    storeSelectedFilterSet(selectedSetName);
    closeCustomFilters();
    setFilters(getFilledFilters(filtersCreated));
    TrackerService.track(FILTERS_APPLY_FILTER_SET, { filters: filtersCreated });
  };

  const toggleFiltersPopover = () => {
    TrackerService.track(FILTERS_MODAL_OPEN);
    if (isFiltersModalOpen) closeCustomFilters();
    if (!isFiltersModalOpen) setIsFiltersModalOpen(true);
  };

  const closeCustomFilters = () => {
    const activeFilterSetName = getActiveFilterSetFromLocalStorage(filterStorageKey);
    // Reset selected filter set to active so that the selected filter set will be the right one upon opening the modal next time
    setSelectedFilterSet(activeFilterSetName);
    displayStoredFilters(activeFilterSetName);
    setNextFilterSetName('');
    setEditingFilterSetName('');
    setNewFilterSetFromName('');
    setIsSavingNewFilterSet(false);
    setIsFiltersModalOpen(false);
    setSelectedFilter(undefined);
    closeCustomFiltersModal();
  };

  // Click outside modal

  const containerRef = createRef<HTMLDivElement>();
  useOnClickOutside(containerRef, () => {
    if (isFilterSetDeleteModalOpen) return;
    closeCustomFilters();
  });

  const isSaveDisabled = !areAllFiltersFilled(filtersCreated);

  // Clicking on any active filter tag opens the filters modal
  useEffect(() => {
    if (selectedFilter !== undefined) {
      setIsFiltersModalOpen(true);
    }
  }, [selectedFilter]);

  // Update filters if they change in the parent
  useEffect(
    () => setFiltersCreated(getValidFilters(filters, comparableColumns)),
    [comparableColumns, filters],
  );

  // Update filters when a filter set is selected and initially when columns have loaded
  useEffect(() => {
    if (newFilterSetFromName) return;
    displayStoredFilters(selectedSetName);
  }, [selectedSetName, displayStoredFilters, newFilterSetFromName]);

  // Select default filter set if no other filter set is selected
  useEffect(() => {
    if (newFilterSetFromName) return;
    if (!selectedSetName) {
      setSelectedFilterSet(DEFAULT_FILTER_SET_NAME);
    }
  }, [selectedSetName, newFilterSetFromName, setSelectedFilterSet, DEFAULT_FILTER_SET_NAME]);

  // Set filter set from with a new filter set will be made even when editing a filter set name (not just when selecting a filter set)
  useEffect(() => {
    setNewFilterSetFromName(editingFilterSetName);
  }, [editingFilterSetName]);

  return (
    <div ref={containerRef}>
      <button
        onClick={toggleFiltersPopover}
        className={cn(
          'e-button e-button--tertiary e-button--small u-display--flex u-align-items--center e-button--oval',
        )}
        data-qa="filters-button"
      >
        <FilterLineDarkIcon className="u-margin-right--small" />
        {translate('analytics_home_filters')}
      </button>
      {isFiltersModalOpen && (
        <div className="u-position--relative c-custom-filters__popover">
          <Popover direction={PopoverDirection.BOTTOM_LEADING}>
            <div className="u-display--flex u-flex-direction--row">
              <FilterSetSelection
                defaultFilterSetName={DEFAULT_FILTER_SET_NAME}
                {...{
                  filtersDisplayedMerged,
                  storedFilterSets,
                  saveFilterSetName,
                  stopEditingFilterSetName,
                  newFilterSetFromName,
                  isSavingNewFilterSet,
                  nextFilterSetName,
                  isSaveDisabled,
                  saveAsNewFilterSet,
                }}
              />
              <VerticalDivider height="stretch" />
              <div className="u-display--flex u-flex-direction--column u-margin--gutter">
                <FilterSelection
                  {...{
                    filtersCreated,
                    removeFilterCreated,
                    updateFilter,
                    columns: comparableColumns,
                    filterTypes,
                    selectedFilter,
                    filtersInOtherDimensions,
                    removeFilterInOtherDimensions,
                  }}
                />
                <ActionButtons
                  {...{
                    createFilter,
                    isDefaultFilterSetSelected,
                    saveAsNewFilterSet,
                    isSaveDisabled,
                    saveFilterSet,
                    applyFilters,
                  }}
                />
              </div>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

const FilterSetSelection: FC<FilterSetSelectionProps> = ({
  filtersDisplayedMerged,
  defaultFilterSetName,
  storedFilterSets,
  saveFilterSetName,
  stopEditingFilterSetName,
  newFilterSetFromName,
  isSavingNewFilterSet,
  nextFilterSetName,
  isSaveDisabled,
  saveAsNewFilterSet,
}) => {
  return (
    <div className="c-custom-filters__popover-filter-set-selection u-display--flex u-flex-direction--column u-padding-left--gutter u-margin-top--gutter u-margin-bottom--gutter">
      <FilterSetOption
        filterSet={{
          name: defaultFilterSetName,
          filters: filtersDisplayedMerged,
        }}
        onSaveName={saveFilterSetName}
        onStopEditing={stopEditingFilterSetName}
        isDefault
      />
      <div className="u-margin-top--gutter u-margin-bottom--tiny">
        {translate('analytics_home_saved_filters')}
      </div>
      {storedFilterSets.map(filterSet => (
        <FilterSetOption
          key={filterSet.name}
          filterSet={filterSet}
          onSaveName={saveFilterSetName}
          onStopEditing={stopEditingFilterSetName}
          fromName={newFilterSetFromName}
        />
      ))}
      {isSavingNewFilterSet && (
        <FilterSetOption
          filterSet={{
            name: nextFilterSetName,
            filters: filtersDisplayedMerged,
          }}
          onSaveName={saveFilterSetName}
          onStopEditing={stopEditingFilterSetName}
          fromName={newFilterSetFromName}
        />
      )}
      <div className="u-display--flex u-margin-top--small u-margin-bottom--small u-padding-left--small">
        {!isSavingNewFilterSet && !isSaveDisabled && (
          <img
            src={iconCreate}
            className="u-color--blue u-cursor--pointer"
            onClick={saveAsNewFilterSet}
            data-qa="filter-sets-create"
          />
        )}
      </div>
    </div>
  );
};

const FilterSelection: FC<FiltersSelectionProps> = ({
  filtersCreated,
  removeFilterCreated,
  updateFilter,
  columns,
  filterTypes,
  selectedFilter,
  filtersInOtherDimensions,
  removeFilterInOtherDimensions,
}) => {
  return (
    <div className="c-custom-filters__popover-filter-selection u-margin-bottom--tiny">
      {filtersCreated.map((filterTmp, index) => (
        <InputFilter
          key={index}
          filter={filterTmp}
          onRemoveFilter={() => removeFilterCreated(index)}
          onUpdateFilter={updateFilter}
          columns={columns}
          filterTypes={filterTypes}
          isSelected={filterTmp === selectedFilter}
          isValid={isFilterValid(filterTmp, columns)}
          isOneFilter={filtersCreated.length === 1}
          index={index}
        />
      ))}
      {filtersInOtherDimensions.map((filterTmp, index) => (
        <InputFilterFromOtherDimension
          key={index}
          filter={filterTmp}
          onRemoveFilter={() => removeFilterInOtherDimensions(index)}
          filterTypes={filterTypes}
          index={index}
        />
      ))}
    </div>
  );
};

const ActionButtons: FC<ActionButtonsProps> = ({
  createFilter,
  isDefaultFilterSetSelected,
  saveAsNewFilterSet,
  isSaveDisabled,
  saveFilterSet,
  applyFilters,
}) => {
  return (
    <>
      <div className="u-display--flex u-justify-content--space-between u-margin-bottom--large">
        <img
          src={iconCreate}
          className="u-color--blue u-cursor--pointer"
          onClick={createFilter}
          data-qa="filters-create"
        />
      </div>
      <div className="u-display--flex u-align-items--center">
        {isDefaultFilterSetSelected ? (
          <ButtonConfirm
            className="e-button e-button--small e-button--tertiary u-display--flex u-align-items--center"
            onClick={saveAsNewFilterSet}
            label={translate('analytics_filters_save_as')}
            labelConfirmed={translateHtml('common_success')}
            buttonProps={{
              disabled: isSaveDisabled,
            }}
          />
        ) : (
          <ButtonConfirm
            className="e-button e-button--small e-button--tertiary u-display--flex u-align-items--center"
            onClick={saveFilterSet}
            label={translate('common_save')}
            labelConfirmed={translateHtml('common_success')}
            buttonProps={{
              disabled: isSaveDisabled,
            }}
          />
        )}
        <div className="u-margin-left--auto u-display--flex">
          <button
            className="e-button e-button--small e-button--primary"
            type="button"
            onClick={applyFilters}
            data-qa="filters-apply"
            disabled={isSaveDisabled}
          >
            {translate('common_apply')}
          </button>
        </div>
      </div>
    </>
  );
};
